import styled from "styled-components";
import background from '../../assets/images/magnet_people.jpg';

export const LoginPage = styled.div`
    background-size: auto;
    background: #ffffff url(${background}) no-repeat center;
    min-width: 100vw;
    min-height: 100vh;
`;

export const MaterialButton = styled.button`

`;
