import styled from "styled-components";
import {PRIMARY_THEME_COLOR} from "../../consts/themes";

export const Container = styled.div`
    width:100%;
    min-height:300px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DropZoneContainer = styled.div`
    width:100%;
    height:300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const DataPreviewContainer = styled.div`
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

export const FileListContainer = styled.div`
      position: relative;
      flex: 1;
      margin: 5px;
      min-height: 300px;
      width: 70%;
      padding: 0 0 10px 0;
      text-align: center;
      border: 1px solid lightgrey;
      border-radius: 5px;
      box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: center;
`;

export const AllFilesContainer = styled.div`
overflow-y: auto;
    padding: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
