import React from 'react';
import Grid from "@material-ui/core/Grid";
import {gridStyle, gridStyleFlex, paperStyle, selectStyling} from "../create_activation_form_components";
import TagsInput from "../../../components/tag_input/tag_input";
import {selectError, textError, textErrorMessage} from "../../../utils/errorHandlers";
import Switch from "../../../components/switch/switch";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "../../../components/ReactSelectAll/ReactSelectAll";
import {period_select_options} from "../../../consts/constants";
import {useSelector} from "react-redux";
import useScrollToViewRef from "../../../hooks/useScrollToViewRef";
import ActivationSMSEndDateField from "./ActivationSMSEndDateField";

const GenerateConditionalSms = (props) => {
    const [activeElement] = useScrollToViewRef();
    const formErrors = useSelector(state => state.activations.activation_form_errors);
    const {label, send_sms_field_name, sms_text_field_name, onInputChange, touched} = props;
    const form_values = useSelector(state => state.activations.formValues);

    const generateSMSDelay = (sms_type) => {
        const field = sms_type === 'follow_up' ? "follow_up_sms_delay" : "interval_between_messages";
        const label = "Enter the delay before sending the " + sms_type.replace('_',' ') + " SMS";
        const default_option =  sms_type === 'follow_up' ? 0 : 2;
        const onEntryPeriodChange = (value) => {
            let entry_value = form_values[field].split(' ')[0];
            onInputChange(field, entry_value + " " + value);
        };

        const onEntryValueChange = (value) => {
            let entry_period = form_values[field].split(' ')[1];
            onInputChange(field, value + " " + entry_period);
        };

        return (
            <Grid style={gridStyleFlex} item>
                <Paper style={paperStyle} elevation={2}>

                    <div style={{display: "flex", flexDirection:"row"}} ref={activeElement}>
                        <div style={{flex: "1", textAlign:"center"}}>
                            <TextField
                                error={textError(touched, formErrors, field)}
                                helperText={textErrorMessage(touched, formErrors, field)}
                                required
                                id={field + "_value"}
                                name={field + "_value"}
                                label={label}
                                type={"number"}
                                value={form_values[field].split(' ')[0]}
                                onChange={e => onEntryValueChange(e.target.value)}
                                fullWidth
                                inputProps={{ min: "1" }}
                            />
                        </div>
                        <div style={{flex: "1", textAlign:"center", marginLeft: "10px"}}>
                            <Select
                                errorText={selectError(touched, formErrors, field)}
                                styles={selectStyling}
                                options={period_select_options}
                                isSearchable={false}
                                defaultValue={period_select_options[default_option]}
                                placeholder={"Please select the period"}
                                name={field + "_period"}
                                id={field + "_period"}
                                onChange={event => onEntryPeriodChange(event.value)}
                            />
                        </div>
                    </div>
                </Paper>
            </Grid>
        );
    };

    const showSMSField = () => {
        if (!form_values[send_sms_field_name])
            return null;
        const fieldValue = form_values[sms_text_field_name] ? form_values[sms_text_field_name] : "";
        const initialValue = fieldValue !== "" ? [fieldValue] : [];

        return (
            <React.Fragment>
                {send_sms_field_name === "send_follow_up_sms" && generateSMSDelay('follow_up')}
                {send_sms_field_name === "send_sms" && generateSMSDelay('recurring')}
                <Grid style={gridStyle} item ref={activeElement}>
                    <TagsInput
                        id={sms_text_field_name}
                        key={sms_text_field_name}
                        title={label + " Text"}
                        initialValue={initialValue}
                        onChange={(data) => onInputChange(sms_text_field_name, data)}
                        error={textErrorMessage(sms_text_field_name)}
                    />
                </Grid>

                {
                    send_sms_field_name === "send_sms" &&
                    <ActivationSMSEndDateField
                        form_values={form_values}
                        onInputChange={onInputChange}/>
                }
            </React.Fragment>

        );
    };

    return (
        <React.Fragment>
            <Grid style={{...gridStyle, height: "50px"}} item>
                <Switch
                    index={label}
                    label={"Send " + label + "?"}
                    isOn={form_values[send_sms_field_name]}
                    onChange={(data)=>onInputChange(send_sms_field_name, data)}
                />
            </Grid>
            {showSMSField()}
        </React.Fragment>
    );
};

export default GenerateConditionalSms;