import React from 'react';
import Grid from "@material-ui/core/Grid";
import {gridStyleFlex, Label, paperStyle} from "../create_activation_form_components";
import {Paper} from "@material-ui/core";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {min_end_time, min_start_time} from "./ActivationDateUtils";
import {addMinutesToDate} from "../../../utils/date_utils";
import {useSelector} from "react-redux";



const ActivationStartEndFields = ({onInputChange}) => {
        const form_values = useSelector(state => state.activations.formValues);
        let localMinEndTime = min_end_time;
        const onDateChange = (tag, date) => {
        if (tag === 'start_time'){
            let end_time = addMinutesToDate(date, 1);
            localMinEndTime = end_time;
            onInputChange('date', {
                'start_time': date.toISOString(),
                'end_time': end_time
            });

        }
        else {
            let start_time = new Date(form_values['start_time']);
            if (date >= addMinutesToDate(start_time, 1)){
                onInputChange('end_time', date.toISOString());
            }
        }
    };

    return (
            <Grid style={gridStyleFlex} item>
                <Paper style={paperStyle} elevation={2}>
                    <div style={{display: "flex", flexDirection:"row"}}>
                        <div style={{flex: "1", textAlign:"center"}}>
                            <Label>{"Activation Start Time"}</Label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    ampm={false}
                                    value={form_values['start_time']}
                                    onChange={(data)=>onDateChange("start_time",data)}
                                    minDate={min_start_time}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div style={{flex: "1", textAlign:"center"}}>
                            <Label>{"Activation End Time"}</Label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    ampm={false}
                                    value={form_values['end_time']}
                                    onChange={(data)=>onDateChange("end_time",data)}
                                    minDate={localMinEndTime}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </Paper>
            </Grid>
        );
};

export default ActivationStartEndFields;