export const selectError = (touched, formErrors, field) => {
    return touched[field ] ? (formErrors[field] ? formErrors[field] : null) : null
};

export const textError = (touched, formErrors, field) => {
    return !!(touched[field] && formErrors[field])
};

export const textErrorMessage = (touched, formErrors, field) => {
    return touched[field] ? (formErrors[field] ? formErrors[field] : null) : null
};