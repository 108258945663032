import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import {Paper} from "@material-ui/core";
import {PRIMARY_THEME_COLOR, SECONDARY_THEME_COLOR} from "../../consts/themes";
import PropTypes from 'prop-types';

const TagsInput = (props) => {
    const [tags, setTags] = React.useState(props.initialValue ? props.initialValue : []);
    const [text_field, setTextField] = React.useState("");
    const [error, setError] = useState(false);

    const inputKeyDown = (e) => {
        let val;
        let temp_tags = [...tags];

        if (typeof(e) === "string"){
            if (!(e.length >= 1)) return;
            let text_input = document.getElementById(`tag_input_${props.id}`).value;
            if (text_input.length >= 1){
                temp_tags.push(text_input);
                setTextField("");
            }
            val = e;
            temp_tags.push(val);
        }
        else {
            val = e.target.value;

            if (e.key === 'Enter' && val && val.length >= 1)
                temp_tags.push(val);

            else return;
        }
        if (temp_tags.join('').length <= 160){
            setTags(temp_tags);
            setTextField("");
            setError(false);
            props.onChange(temp_tags.join(''))
        }
        else {
            setError("The SMS length will be greater than 160 characters. Please adjust your message")
        }


    };

    const removeTags = index => {
        let temp_tags = [...tags.filter(tag => tags.indexOf(tag) !== index)];
        setTags(temp_tags);
        props.onChange(temp_tags.join(''))
    };

    const showMessage = () => {
        if (tags.length === 0)
            return null;

        return (
            <div>
                <h4 style={{color:"#808080"}}>This is what your message will look like:</h4>
                <p style={{color:PRIMARY_THEME_COLOR}}>{tags.join('')}</p>
            </div>
        );
    };

    const renderInputButton = (label, value) => {
        return (
            <Tag
                backgroundColor={PRIMARY_THEME_COLOR}
            >
                <TagTitle
                    onClick={() => inputKeyDown(value)}
                >{label}</TagTitle>
            </Tag>
        );
    };

    const renderTitle = () => {
        if (!props.title)
            return null;
        return (
            <h4 style={{color:"#808080"}}>{props.title}</h4>
        );
    };

    return (
        <Paper style={paperStyle} elevation={3}>
            {renderTitle()}
            <Tags>
                {renderInputButton("Name", "<-name-->")}
                {renderInputButton("Surname", "<surname>")}
                {renderInputButton("Cellphone Number", "<cell-no.>")}
                {renderInputButton("Activation Name", "<----activation_name---->")}
                {renderInputButton("Campaign Name", "<-----campaign_name----->")}
                {renderInputButton("Date", "<--date-->")}
                {renderInputButton("Time", "<-time->")}
            </Tags>
            <TextField
                required
                onKeyDown={inputKeyDown}
                value={text_field}
                onChange={(e)=>setTextField(e.target.value)}
                id={`tag_input_${props.id}`}
                name="tag_input"
                error={!!error || !!props.error}
                helperText={error ? error : (props.error ? props.error : "")}
                label="Please type your SMS text here and press enter. You may also add tags from above:"
                fullWidth
            />
            <br/>
            <Tags>
                {tags.map((tag, index) => (
                    <Tag key={index}>
                        <TagTitle>{tag}</TagTitle>
                        <TagCloseIcon
                              onClick={() => removeTags(index)}
                        >
                            x
                        </TagCloseIcon>
                    </Tag>
                ))}
            </Tags>
            {showMessage()}
        </Paper>
    );
};

TagsInput.propTypes = {
    onChange: PropTypes.func,
    title: PropTypes.string
};

export default TagsInput;




const Tags = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
`;

const Tag = styled.li`
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: ${props => props.backgroundColor ? props.backgroundColor : SECONDARY_THEME_COLOR};
`;


const TagTitle = styled.span`
    margin-top: 3px;
`;

const TagCloseIcon = styled.span`
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: ${SECONDARY_THEME_COLOR};
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    
`;

const paperStyle = {
    topMargin: "10px",
    padding: "1em",
    justifyContent: "center",
    width: "100%",

};
