import styled from "styled-components";
import {PRIMARY_THEME_COLOR} from "../../consts/themes";

export const WifiAdminBox = styled.div`
    float: left;
    width: 336px;
    min-height: 100%;
    background-color: white;
    margin-bottom: 40px;
    padding: 40px 40px 40px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 2px;
    border-color: #E3E3E3;
    display: block;
`;

export const WifiAdminFormBox = styled.div`
    display: block;
`;

export const WifiAdminFormBoxCentralized = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const WifiAdminFormButtonContainer = styled.div`
    display:flex;
    flex-direction: row;
    width: 100%;
    height: 35px;
    justify-content: center;
`;

export const PortalFormButton = styled.div`
    float: left;
    width: ${props => props.width ? props.width : '45%'};
    height: 35px;
    margin-right: ${props => props.marginRight ? props.marginRight : '5%'};
    margin-top: 0;
    border: 0;
    border-radius: 4px;
    color: #fff;
    background: ${PRIMARY_THEME_COLOR};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 40%;
    flex: 1;
    cursor:pointer;
`;

export const WifiAdminFormCancelButton = styled.div`
    float: left;
    width: ${props => props.width ? props.width : '45%'};
    height: 35px;
    margin-right: 5%;
    margin-top: 0;
    border: 0;
    border-radius: 2px;
    color: #000000;
    background: #eee;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor:pointer;
`;

export const WifiAdminFormAreaLabel = styled.div`
    margin: 0;
    font-family: "proxima-nova-1","proxima-nova-2", "Helvetica Neue", Helvetica, verdana, sans-serif;
    font-style: normal;
    font-size: 13px;
    color: #666;
    -webkit-font-smoothing: antialiased;
`;

export const WifiAdminFormAreaFieldSet = styled.div`
    display: inline-block;
    width: 100%;
    min-height: 30px;
    margin: 0 0 10px;
    padding: 0 0 15px;
`;

export const WifiAdminFormInputBox = styled.input`
    width: 240px;
    float: none;
    padding: 7px;
    background: #ededed;
    color: #666;
    border: solid 1px #ccc;
    font-size: 1em;
    display: block;
`;

export const WifiAdminFormSelectBox = styled.select`
    width: 240px;
    float: none;
    padding: 7px;
    background: #ededed;
    color: #666;
    border: solid 1px #ccc;
    font-size: 1em;
    display: block;
`;

export const WifiAdminFormAreaBottom = styled.div`
    display: inline-block;
    margin-top: 10px;
`;

export const FormAreaBottomLink = styled.a`
    color: #1795E6;
    text-decoration: none;
`;

export const WifiAdminFormUL = styled.ul`
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    margin: 0;
    padding: 0;
`;


export const WifiAdminFormLI = styled.li`
    display: inline;
    list-style: none;
    margin-right: 0;
    text-align: -webkit-match-parent;
    
    &:first-child {
        border-right: 1px solid #eee;
        padding-right: 9px;
        margin-right: 6px;
    }
`;
