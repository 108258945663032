import React, {useState} from "react";
import {
    WifiAdminFormBox,
    WifiAdminFormInputBox,
    WifiAdminFormAreaFieldSet,
    WifiAdminFormAreaLabel,
    PortalFormButton,
    FormAreaBottomLink,
    WifiAdminFormUL,
    WifiAdminFormLI, WifiAdminFormAreaBottom, WifiAdminBox
} from '../../components/doctor_portal_form/doctor_portal_form_components'

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {resetPasswordButtonPressed} from "../../../redux/actions/reset_password_scene";
function ForgotPasswordContent(props) {

    const [formValues, setFormValues] = useState({});

    const onFormChanged = (field, data) => {
        let temp_form_values = {...formValues};
        temp_form_values[field] = data;
        setFormValues(temp_form_values);
    };

    return (
        <WifiAdminBox>
                <h1>Password Reset</h1>
                <WifiAdminFormBox>
                    <WifiAdminFormAreaFieldSet>
                        <WifiAdminFormAreaLabel>Enter your email address</WifiAdminFormAreaLabel>
                        <WifiAdminFormInputBox
                            maxLength="128"
                            autofill={"none"}
                            name="email"
                            type="email"
                            onChange={(data)=>{onFormChanged("username", data.target.value)}}
                        />
                    </WifiAdminFormAreaFieldSet>

                    <PortalFormButton
                        onClick={()=>{props.resetPasswordButtonPressed(formValues)}}
                    >
                        Reset Password
                    </PortalFormButton>

                    <WifiAdminFormAreaBottom>
                        <WifiAdminFormUL>
                            <WifiAdminFormLI><FormAreaBottomLink href="/login">Login</FormAreaBottomLink></WifiAdminFormLI>
                            <WifiAdminFormLI><FormAreaBottomLink href="https://marc1.io">Marc1</FormAreaBottomLink></WifiAdminFormLI>
                        </WifiAdminFormUL>
                    </WifiAdminFormAreaBottom>

                </WifiAdminFormBox>
        </WifiAdminBox>
    );
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {resetPasswordButtonPressed})(withRouter(ForgotPasswordContent));
