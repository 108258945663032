import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,

	Menu,


} from "@material-ui/core";
import {
	Menu as MenuIcon,
	Person as AccountIcon,
	ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import {logoutUser} from "../../../redux/actions/auth";
import logo from '../../assets/images/marc1.png';
// styles
import useStyles from "./styles";
import {UsernameContainer} from './header_components';

// components
import {  Typography } from "../Wrappers/Wrappers";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import {
	useLayoutState,
	useLayoutDispatch,
	toggleSidebar,
} from "../../context/LayoutContext";

import {useDispatch, useSelector} from 'react-redux';
import Button from "@material-ui/core/Button";

export default function Header(props) {
	const user = useSelector(state => state.localStorage.user || {});
	const dispatch = useDispatch();
	const classes = useStyles();

	// global
	const layoutState = useLayoutState();
	const layoutDispatch = useLayoutDispatch();

	// local
	const [profileMenu, setProfileMenu] = useState(null);

	return (
	  <AppBar position="fixed" className={classes.appBar}>
		  <Toolbar className={classes.toolbar}>
			  <IconButton
				color="inherit"
				onClick={() => toggleSidebar(layoutDispatch)}
				className={classNames(
				  classes.headerMenuButton,
				  classes.headerMenuButtonCollapse,
				)}
			  >
				  {layoutState.isSidebarOpened ? (
					<ArrowBackIcon
					  classes={{
						  root: classNames(
							classes.headerIcon,
							classes.headerIconCollapse,
						  ),
					  }}
					/>
				  ) : (
					<MenuIcon
					  classes={{
						  root: classNames(
							classes.headerIcon,
							classes.headerIconCollapse,
						  ),
					  }}
					/>
				  )}
			  </IconButton>
			  <Button
				  color="inherit"
				  style={{backgroundColor:"#f9f9f92e", minHeight: "inherit"}}
			  >
				  <img style={{height: '40px'}} src={logo}/>
			  </Button>
			  <Typography variant="h6" weight="medium" className={classes.logotype}>
				  Magnetic Force Portal - {layoutState.appBarHeaderTitle}
			  </Typography>
			  <div className={classes.grow}/>

			  <IconButton
				aria-haspopup="true"
				color="inherit"
				className={classes.headerMenuButton}
				aria-controls="profile-menu"
				onClick={e => setProfileMenu(e.currentTarget)}
			  >
				  <AccountIcon classes={{ root: classes.headerIcon }}/>
			  </IconButton>

			  <Menu
				id="profile-menu"
				open={Boolean(profileMenu)}
				anchorEl={profileMenu}
				onClose={() => setProfileMenu(null)}
				className={classes.headerMenu}
				classes={{ paper: classes.profileMenu }}
				disableAutoFocusItem
			  >
				  <div className={classes.profileMenuName}>
					  <AccountIcon classes={{ root: classes.headerMenuIcon }}/>
					  <UsernameContainer>
						  <Typography
							color="secondary"
						  >
							  {user.full_name}
						  </Typography>
					  </UsernameContainer>
				  </div>
				  <div className={classes.profileMenuUser}>
					  <Typography
						className={classes.profileMenuLink}
						color="primary"
						onClick={() => dispatch(logoutUser())}
					  >
						  Sign Out
					  </Typography>
				  </div>
			  </Menu>
		  </Toolbar>
	  </AppBar>
	);
}
