import React, {useEffect, useState} from "react";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import PropTypes from 'prop-types';
import {setTitle, useLayoutDispatch} from "../../context/LayoutContext";
import FullscreenSpinner from "../../components/Loader/fullscreen_spinner";
import {
    LayoutContainer,
    PaperContainer,
    paperStyle,
    gridStyle,
    gridStyleFlex, selectStyling
} from './create_activation_form_components';
import {withRouter} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import Select from '../../components/ReactSelectAll/ReactSelectAll';
import * as actions from "../../../redux/actions/action-types";

import {activationsForm} from "../../consts/constants";
import GenerateEntryPeriod from "./activation_form_components/GenerateEntryPeriod";
import {selectError, textError, textErrorMessage} from "../../utils/errorHandlers";
import GenerateConditionalSms from "./activation_form_components/GenerateConditionalSMS";
import ActivationFormActionButtons from "./activation_form_components/ActivationFormActionButtons";
import {activationsFormAdjustments} from "./activation_form_components/ActivationDateUtils";
import ActivationStartEndFields from "./activation_form_components/ActivationStartEndFields";
import {validateActivationForm} from "../../../redux/actions/activations";


function CreateActivationForm(props) {
    const layoutDispatch = useLayoutDispatch();
    const initialFormValues = props.initialValues ?
        {...props.initialValues, ...activationsFormAdjustments} : {...activationsForm, ...activationsFormAdjustments};

    const form_values = useSelector(state => state.activations.formValues);
    const [touched, setTouched] = useState({});
    const campaigns = useSelector(state => state.campaigns.campaigns);
    const [campaign_options, setCampaignOptions] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const stores = useSelector(state => state.stores);
    const [store_list, setStoreList] = useState([]);

    const network_activity = useSelector(state => state.network);
    const formErrors = useSelector(state => state.activations.activation_form_errors);

    const dispatch = useDispatch();
    const path = useSelector(state => state.path);

    useEffect(()=>{
        if (isLoaded) props.history.push(path)
        else {
            dispatch({
                type: actions.SET_APPLICATION_PATH,
                payload:'/dashboard/view_activations/create_activation'
            });
            setIsLoaded(true);
        }
    }, [path]);

    const initializeFormValues = () => {
        dispatch({type: actions.VALIDATE_ACTIVATION_FORM, payload: initialFormValues})
    };

    useEffect(() => {
        initializeFormValues();
        setTitle(layoutDispatch, "Create an Activation");
        dispatch({type: actions.GET_ALL_CAMPAIGNS});
        dispatch({type: actions.GET_ALL_STORES})

        return () => {
            dispatch({type: actions.CLEAR_ACTIVATION_DATA});
        };
    }, [dispatch]);

    useEffect(()=>{
        let campaign_select_options = campaigns.map(({campaign_id, campaign_name})=>{
            return {value: campaign_id, label: campaign_name}
        });
        setCampaignOptions(campaign_select_options);
    }, [campaigns]);

    useEffect(()=>{
        let store_options = stores.map((store)=>{return {value: store, label: store}});
        setStoreList(store_options);
    }, [stores]);

    const onInputChange = (field, value) => {
        setTouched({...touched, [field]: true});
        let temporary_form_values;
        if (typeof(value) === "object" && !Array.isArray(value))
            temporary_form_values = {...form_values, ...value};
        else{
            temporary_form_values = {...form_values};
            temporary_form_values[field] = value;
        }
        dispatch(validateActivationForm(temporary_form_values));
    };

    let network_loading = !_.isEmpty(network_activity);
    let campaigns_loading = "get_all_campaigns" in network_activity;
    let stores_loading = "get_all_stores" in network_activity;
    let isLoading = network_loading && !campaigns_loading && !stores_loading;

    if (_.isEmpty(form_values))
        return <FullscreenSpinner isLoading={true}/>

    return (
        <div>
            <FullscreenSpinner isLoading={isLoading}/>
            <LayoutContainer>
                <PaperContainer>
                    <Paper style={{...paperStyle, width: "90%"}} elevation={1}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    required
                                    error={textError(touched, formErrors,"activation_name")}
                                    helperText={textErrorMessage(touched, formErrors,"activation_name")}
                                    id="Activation Name"
                                    name="Activation Name"
                                    label="Enter the Activation Name"
                                    onChange={e => onInputChange("activation_name", e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid style={gridStyle} item>
                                <Select
                                    errorText={selectError(touched, formErrors,"campaign_id")}
                                    styles={selectStyling}
                                    options={campaign_options}
                                    isSearchable={true}
                                    isLoading={campaigns_loading}
                                    placeholder={"Please select the campaign"}
                                    name={"campaign_id"}
                                    onChange={event => onInputChange('campaign_id', event.value)}
                                />
                            </Grid>
                            <Grid style={gridStyle} item>
                                <Select
                                    closeMenuOnSelect={false}
                                    errorText={selectError(touched, formErrors, "stores")}
                                    styles={selectStyling}
                                    isMulti
                                    value={form_values.stores.map((store=>{return {value: store, label: store}}))}
                                    allowSelectAll
                                    options={store_list}
                                    isSearchable={true}
                                    placeholder={"Please select the stores for this activation..."}
                                    name={"stores"}
                                    isLoading={stores_loading}
                                    onChange={event => onInputChange('stores', event ? event.map(a => a.value) : [])}
                                />
                            </Grid>

                            <ActivationStartEndFields
                                onInputChange={onInputChange}/>

                            <GenerateEntryPeriod
                                onInputChange={onInputChange}
                                formErrors={formErrors}
                                touched={touched}/>

                            <Grid style={gridStyleFlex} item>
                                <Paper style={paperStyle} elevation={2}>
                                    <GenerateConditionalSms
                                        label={"First SMS"}
                                        send_sms_field_name={"send_first_sms"}
                                        sms_text_field_name={"first_sms_text"}
                                        onInputChange={onInputChange}
                                        touched={touched}/>
                                    <GenerateConditionalSms
                                        label={"Follow up SMS"}
                                        send_sms_field_name={"send_follow_up_sms"}
                                        sms_text_field_name={"follow_up_sms_text"}
                                        onInputChange={onInputChange}
                                        touched={touched}/>
                                    <GenerateConditionalSms
                                        label={"Recurring SMS"}
                                        send_sms_field_name={"send_sms"}
                                        sms_text_field_name={"activation_sms"}
                                        onInputChange={onInputChange}
                                        touched={touched}/>
                                </Paper>
                            </Grid>
                        </Grid>
                        <ActivationFormActionButtons {...props}/>
                    </Paper>
                </PaperContainer>
            </LayoutContainer>
        </div>
    );
}
CreateActivationForm.propTypes = {
    initialValues: PropTypes.object,

};

export default withRouter(connect(null, {})(CreateActivationForm));




