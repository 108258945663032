import React, {useState} from "react";

import {connect} from "react-redux";
import {loginButtonPressed, usernameChanged} from '../../../../redux/actions/login_scene';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
const styles = () => ({
    disabledButton: {
        backgroundColor: 'red'
    }
});

function Username(props) {

    const [username, setUsername] = useState(null);

    const onChange = (event) => {
        setUsername(event);
        props.usernameChanged(event);
    };

    const onNext = () => {
        props.setUsername(username);
        props.onNext();
    };

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            if (props.emailValid === false){
                onNext()
            }
            ev.preventDefault();
        }
    };

    return (
        <React.Fragment>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                autoComplete="email"
                name="email_address"
                label="Email Address"
                type="email"
                id="username"
                onKeyPress={(ev) => onKeyPress(ev)}
                error={!!props.emailValid}
                helperText={props.emailValid ? props.emailValid : 'Enter your email address'}
                onChange={(event)=>{onChange(event.target.value)}}
            />
            <Button
                style={{float: "right"}}
                onClick={() => {onNext()}}
                type="button"
                color={"secondary"}
                disabled={props.emailValid !== false}
                variant="contained">
                Next
            </Button>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    const {login} = state;
    return {
        emailValid: login.emailValid
    }
}

export default connect(mapStateToProps, {usernameChanged})(Username);


