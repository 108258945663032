// Generic perform no operation action. No reducer should use this.
export const NO_OP = 'NO_OP';


// api
export const API = 'API';
export const API_POST = 'API_POST';
export const API_GET = 'API_GET';
export const API_PATCH = 'API_PATCH';
export const API_PUT = 'API_PUT';


// network state

export const END_NETWORK = 'END_NETWORK';
export const START_NETWORK = 'START_NETWORK';


// Application
export const SET_APPLICATION_PATH = "SET_APPLICATION_PATH";


// Login Scene
export const LOGIN_USERNAME_CHANGED = "LOGIN_USERNAME_CHANGED";
export const EMAIL_VALID = "EMAIL_VALID";
export const LOGIN_BUTTON_SUBMIT_ACTION = "LOGIN_BUTTON_SUBMIT_ACTION";


// Signup Scene
export const SIGNUP_BUTTON_SUBMIT_ACTION = "SIGNUP_BUTTON_SUBMIT_ACTION";


// auth

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_FAILURE_RESET = 'LOGIN_FAILURE_RESET';


// storage

export const LOAD_LOCAL_STORAGE = 'LOAD_LOCAL_STORAGE';
export const LOCAL_STORAGE_LOADED = 'LOCAL_STORAGE_LOADED';
export const STORE_DEVICE_TOKEN = 'STORE_DEVICE_TOKEN';


// notistack

export const NOTISTACK_SUCCESS = "NOTISTACK_SUCCESS";
export const NOTISTACK_WARNING= "NOTISTACK_WARNING";
export const NOTISTACK_ERROR= "NOTISTACK_ERROR";
export const NOTISTACK_INFO= "NOTISTACK_INFO";
export const RESET_NOTISTACK= "RESET_NOTISTACK";


// Reset password scene

export const RESET_PASSWORD_BUTTON_SUBMIT_ACTION = "RESET_PASSWORD_BUTTON_SUBMIT_ACTION";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

// ID Number Tools

export const CLEAR_ID_NUMBER = "CLEAR_ID_NUMBER";
export const SET_ID_VALID = "SET_ID_VALID";
export const SET_DOB_FROM_ID = "SET_DOB_FROM_ID";
export const SET_GENDER_FROM_ID = "SET_GENDER_FROM_ID";
export const SET_AGE_FROM_ID = "SET_AGE_FROM_ID";
export const ID_NUMBER_CHANGED = "ID_NUMBER_CHANGED";

// Registration Form

export const REGISTRATION_CLEAR_FIELDS = "REGISTRATION_CLEAR_FIELDS";
export const REGISTRATION_FORM_CHANGED = "REGISTRATION_FORM_CHANGED";
export const REGISTRATION_FORM_FILLED = "REGISTRATION_FORM_FILLED";
export const SET_VALID_CELLPHONE_NUMBER = "SET_VALID_CELLPHONE_NUMBER";
export const REGISTRATION_FORM_SUBMIT = "REGISTRATION_FORM_SUBMIT";
export const REGISTRATION_AWAITING_VALIDATION = "REGISTRATION_AWAITING_VALIDATION";
export const REGISTRATION_SET_VALIDATION = "REGISTRATION_SET_VALIDATION";

// Campaigns
export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
export const GET_ALL_CAMPAIGNS_SUCCESS = "GET_ALL_CAMPAIGNS_SUCCESS";
export let GET_CAMPAIGN_VIDEO_UPLOAD_URLS_SUCCESS = "GET_CAMPAIGN_VIDEO_UPLOAD_URLS_SUCCESS";
export let GET_CAMPAIGN_VIDEO_UPLOAD_LINKS = "GET_CAMPAIGN_VIDEO_UPLOAD_LINKS";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILED = "CREATE_CAMPAIGN_FAILED";
export const NEW_CAMPAIGN_VIDEOS = "NEW_CAMPAIGN_VIDEOS";
export const UPLOAD_CAMPAIGN_VIDEO_SUCCESS = "UPLOAD_CAMPAIGN_VIDEO_SUCCESS";
export const UPLOAD_CAMPAIGN_VIDEO_FAILED = "UPLOAD_CAMPAIGN_VIDEO_FAILED";
export const VIDEO_UPLOAD_PROGRESS = "VIDEO_UPLOAD_PROGRESS";
export let VIDEO_UPLOAD_COMPLETE = "VIDEO_UPLOAD_COMPLETE";
export let CLEAR_CAMPAIGN_DATA = "CLEAR_CAMPAIGN_DATA";

// ACTIVATIONS
export const GET_ALL_ACTIVATIONS = "GET_ALL_ACTIVATIONS";
export const GET_ALL_ACTIVATIONS_SUCCESS = "GET_ALL_ACTIVATIONS_SUCCESS";
export let VALIDATE_ACTIVATION_FORM = "VALIDATE_ACTIVATION_FORM";
export let SET_ACTIVATION_FORM_VALIDITY = "SET_ACTIVATION_FORM_VALIDITY";
export let SET_ACTIVATION_FORM_ERRORS = "SET_ACTIVATION_FORM_ERRORS";
export let ACTIVATION_FORM_SUBMIT = "ACTIVATION_FORM_SUBMIT";
export let CREATE_ACTIVATION_SUCCESS = "CREATE_ACTIVATION_SUCCESS";
export let CLEAR_ACTIVATION_DATA = "CLEAR_ACTIVATION_DATA";
export const CANCEL_ACTIVATION_SUCCESS = "CANCEL_ACTIVATION_SUCCESS";
export const CANCEL_ACTIVATION_FAILED = "CANCEL_ACTIVATION_FAILED";

// STORES
export let GET_ALL_STORES ="GET_ALL_STORES";
export let GET_ALL_STORES_SUCCESS = "GET_ALL_STORES_SUCCESS";
export let CLEAR_STORE_DATA = "CLEAR_STORE_DATA";
