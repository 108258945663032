import styled from "styled-components";
import {PRIMARY_THEME_COLOR} from "../../consts/themes";

export const QuickStartContainer = styled.div`
      width:100%;
      height:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 25px;
      background-color: #ffffffe0;
      overflow: auto;
`;

export const CardDiv = styled.div`
  position: relative;
  flex: 1;
  margin: 5px;
  height: 50%;
  width: 70%;
  padding: 10px;
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 5px;
  cursor:${props => props.disabled ? 'unset' : 'pointer'};
  opacity: ${props => props.disabled ? 0.3 : 1};
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  
  &:hover {
        border: ${props => props.disabled ? `1px solid lightgrey` : `1px solid ${PRIMARY_THEME_COLOR}`};
    }
  
`;

export const ImageContainer = styled.div`
    margin-bottom: 10px;
    max-height: 50%;
    flex: 1;
`;
export const Image = styled.img`
    margin-bottom: 10px;
    max-height: 100%;
    object-fit: contain;
    width: 100%;
    flex: 1;
`;

export const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const DisabledOverlay = styled.div`
    border-radius: 5px;
    margin: -10px;
    background: rgba(0, 0, 0, .54);
    position: absolute;
    width: 100%;
    height: 100%;
`;
