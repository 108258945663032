import React, {useEffect} from 'react';
import BaseLayout from '../../components/base_layout/base_layout';
import FAB from '../../components/custom_buttons/FAB';
import {withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import CampaignsTable from "./campaigns_table";
import {CampaignsContainer} from './campaigns_components';
import FullscreenSpinner from "../../components/Loader/fullscreen_spinner";
import {ActivationsContainer} from "../view_activations_scene/activations_components";
import {setTitle, useLayoutDispatch} from "../../context/LayoutContext";

const ViewCampaignsScene = (props) => {
    let networkActive = useSelector(state => state.network);
    const layoutDispatch = useLayoutDispatch();

    useEffect(() => {
        setTitle(layoutDispatch, "View Campaigns");
    }, []);
    return (
        <BaseLayout>
            <FAB
                size="small"
                buttonIcon={<AddIcon/>}
                onClick={()=>{props.history.push('/dashboard/view_campaigns/create_campaign')}}
            />

            <CampaignsContainer>
                <FullscreenSpinner isLoading={!_.isEmpty(networkActive)}/>
                <CampaignsTable/>
                <br/><br/><br/><br/><br/>
            </CampaignsContainer>
        </BaseLayout>
    );
};

export default withRouter(connect(null, {})(ViewCampaignsScene));
