import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers';
import ReduxThunk from 'redux-thunk'
import promise from "redux-promise";

import api from './middleware/core/api';
import storage from './middleware/core/storage';
import portal_interface from './middleware/feature/portal_interface';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(
    ReduxThunk,
    promise,
    storage,
    portal_interface,
    api,
)));

window.store = store;

export default store;
