import {formError} from "../../../actions/auth";

const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validate_reset_password_form = (action) => {
    if (!action.payload.username){
        return(formError("Please enter your email address"));
    }

    if (!validateEmail(action.payload.username)){
        return(formError("Please enter a valid email address"));
    }

    return false;
};