import {formError} from "../../../actions/auth";

const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validate_registration_form = (action) => {
    if (!action.payload.full_name){
        return(formError("Please enter your Full Name"));
    }

    if (action.payload.full_name.length < 4){
        return(formError("Full Name less than 4 characters"));
    }

    if (!action.payload.username){
        return(formError("Please enter your email address"));
    }

    if (!validateEmail(action.payload.username)){
        return(formError("Please enter a valid email address"));
    }

    if (!action.payload.password || !action.payload.confirm_password){
        return(formError("Please enter a password in both password fields"));
    }

    if (action.payload.password.length < 6){
        return(formError("Please enter a password at least 6 characters long"));
    }

    if (action.payload.password !== action.payload.confirm_password){
        return(formError("Passwords do not match!"));
    }

    if (!action.payload.organization_name){
        return(formError("Please enter a company name"));
    }

    if (action.payload.organization_name.length < 3){
        return(formError("Company name too short"));
    }

    return false;
};