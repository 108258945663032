import * as actions from './action-types';

export const fetchLocalStorage = (dataToLoad) => ({
    type: actions.LOAD_LOCAL_STORAGE,
    payload: dataToLoad
});

export const saveLocalStorageToState = (data) => ({
    type: actions.LOCAL_STORAGE_LOADED,
    payload: data
});