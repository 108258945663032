import React from 'react';
import Grid from "@material-ui/core/Grid";
import {ButtonContainer, gridStyle} from "../create_activation_form_components";
import {Button} from "@material-ui/core";
import * as actions from "../../../../redux/actions/action-types";
import {useDispatch, useSelector} from "react-redux";

const ActivationFormActionButtons = (props) => {
    const form_values = useSelector(state => state.activations.formValues);

    const dispatch = useDispatch();
    const activation_form_valid = useSelector(state => state.activations.activation_form_valid);

    const handleSubmit = (type) => () => {
        if (type === "add") {
            dispatch({
                type: actions.ACTIVATION_FORM_SUBMIT,
                payload: form_values
            })
        }
    };

    return (
        <Grid container direction="column">
            <Grid style={gridStyle} item>
                <ButtonContainer>
                    <Button style={{}} variant="contained" color="primary" onClick={() => {
                        props.history.push('/dashboard/view_activations');
                    }}
                            fullWidth
                    >
                        Cancel
                    </Button>
                    <Button style={{}} variant="contained" color="secondary" onClick={handleSubmit("add")}
                            fullWidth
                            disabled={!activation_form_valid}
                    >
                        Submit
                    </Button>
                </ButtonContainer>
            </Grid>
        </Grid>
    );
};

export default ActivationFormActionButtons;