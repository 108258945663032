import * as actions from "./action-types";
import {LOGIN_URL} from "../../react/consts/constants";

export const resetLoginFailure = () => ({
    type: actions.LOGIN_FAILURE_RESET
});

export const loginSuccess = (payload) => ({
    type: actions.LOGIN_SUCCESS,
    payload
});

export const signupSuccess = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_SUCCESS,
        payload: "Registered, Please check your email"
    });
    dispatch({
        type: actions.SIGNUP_SUCCESS,
        payload
    })
};

export const resetSuccess = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_SUCCESS,
        payload: "Password Reset, Your new password will be emailed to you"
    });
    dispatch({
        type: actions.PASSWORD_RESET_SUCCESS,
        payload
    })
};


export const resetFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: payload.response ? payload.response.data : payload.toString()
    });
    dispatch({
        type: actions.PASSWORD_RESET_FAILURE,
        payload
    });
};


export const routeToPath = (path) => ({
    type: actions.SET_APPLICATION_PATH,
    payload: path
});


export const loginFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: payload.response.data
    });
    dispatch({
        type: actions.LOGIN_FAILURE,
        payload
    });
};

export const signupFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: payload.response.data
    });
    dispatch({
        type: actions.SIGNUP_FAILURE,
        payload
    });
};

export const formError = (payload) => ({
    type: actions.NOTISTACK_ERROR,
    payload: payload
});

export const loginUser = (credentials) => ({
    type: actions.API,
    payload: {
        full_url: LOGIN_URL + '/login',
        method: actions.API_POST,
        data: credentials,
        failure: (data) => loginFailed(data),
        success: (data) => loginSuccess(data),
        label: 'login',
        authenticate: false
    }
});

export const registerUser = (credentials) => ({
    type: actions.API,
    payload: {
        full_url: LOGIN_URL + '/register',
        method: actions.API_POST,
        data: credentials,
        failure: (data) => signupFailed(data),
        success: (data) => signupSuccess(data),
        label: 'register',
        authenticate: false
    }
});


export const resetUser = (credentials) => ({
    type: actions.API,
    payload: {
        full_url: LOGIN_URL + '/reset-password',
        method: actions.API_POST,
        data: credentials,
        failure: (data) => resetFailed(data),
        success: (data) => resetSuccess(data),
        label: 'reset_password',
        authenticate: false
    }
});


export const logoutUser = payload => ({
    type: actions.LOGOUT_REQUEST,
    payload
});
