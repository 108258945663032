import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {gridStyleFlex, Label, paperStyle} from "../create_activation_form_components";
import {Paper} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils, {startOfDay} from "@date-io/date-fns";
import {min_end_time, min_start_time} from "./ActivationDateUtils";
import {useSelector} from "react-redux";



const ActivationSMSEndDateField = ({onInputChange}) => {
    const form_values = useSelector(state => state.activations.formValues);

    let localMinEndTime = form_values.end_time || min_end_time;

    const getEndOfDay = (date) => {
        return `${date.slice(0,11)}23:59:59.999Z`
    }

    const onDateChange = (tag, date) => {
        onInputChange('sms_end_date', getEndOfDay(date.toISOString()));
    };

    useEffect(()=>{
        localMinEndTime = form_values.end_time || min_end_time;
        onInputChange('sms_end_date', getEndOfDay(localMinEndTime));
    },[localMinEndTime])

    return (
            <Grid style={gridStyleFlex} item>
                <Paper style={paperStyle} elevation={2}>
                    <div style={{display: "flex", flexDirection:"row"}}>
                        <div style={{flex: "1", textAlign:"center"}}>
                            <Label>{"SMS End Date (end of day)"}</Label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disablePast
                                    orientation={'landscape'}
                                    value={form_values['sms_end_date'] || localMinEndTime}
                                    onChange={(data)=>onDateChange("sms_end_date",data)}
                                    minDate={localMinEndTime}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </Paper>
            </Grid>
        );
};

export default ActivationSMSEndDateField;