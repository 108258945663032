import * as actions  from "../actions/action-types";

const DEFAULT_STATE = {
    state: false,
    message: false
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.NOTISTACK_SUCCESS:
            return { ...state, state: "success", message: action.payload};

        case actions.NOTISTACK_ERROR:
            return { ...state, state: "error", message: action.payload};

        case actions.NOTISTACK_INFO:
            return { ...state, state: "info", message: action.payload};

        case actions.NOTISTACK_WARNING:
            return { ...state, state: "info", message: action.payload};

        case actions.RESET_NOTISTACK:
            return { ...state, state: false, message: false};

        default:
            return state;
    }
}