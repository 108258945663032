import React, {useEffect} from 'react';
import BaseLayout from '../../components/base_layout/base_layout';
import ActivationsTable from "./activations_table";
import FAB from "../../components/custom_buttons/FAB";
import AddIcon from '@material-ui/icons/Add';
import {ActivationsContainer} from "./activations_components";
import FullscreenSpinner from "../../components/Loader/fullscreen_spinner";
import {useSelector} from "react-redux";
import {setTitle, useLayoutDispatch} from "../../context/LayoutContext";
import {CampaignsContainer} from "../view_campaigns_scene/campaigns_components";

const ViewActivationsScene = (props) => {
    let networkActive = useSelector(state => state.network);
    const layoutDispatch = useLayoutDispatch();

    useEffect(() => {
        setTitle(layoutDispatch, "View Activation");
    }, []);

    return (
        <BaseLayout>
            <FAB
                size="small"
                buttonIcon={<AddIcon/>}
                onClick={()=>{props.history.push('/dashboard/view_activations/create_activation')}}
            />
            <ActivationsContainer>
                <FullscreenSpinner isLoading={!_.isEmpty(networkActive)}/>
                <ActivationsTable/>
                <br/><br/><br/><br/><br/>
            </ActivationsContainer>
        </BaseLayout>
    );
};

export default ViewActivationsScene;
