import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {AnimateKeyframes} from 'react-simple-animate';
import {Loader, LoaderBar} from './google_login_layout_components';

function GoogleLoader(props) {

    const [loader_active, setLoaderActive] = useState(true);

    useEffect(()=>{
        if (_.isEmpty(props.network)) {
            setLoaderActive(false);
            return;
        }
        setLoaderActive(true);
    },[props.network]);

    if (!loader_active)
        return (<div/>);

    return (
        <Loader>
            <AnimateKeyframes
                play={loader_active}
                iterationCount="infinite"
                duration={1.4}
                direction="normal"
                keyframes={[
                    'transform: translateX(-1000px)',
                    'transform: translateX(1000px)',
                ]}
            >
                <LoaderBar/>
            </AnimateKeyframes>
        </Loader>
    );

}

function mapStateToProps(state) {
    const {network} = state;

    return {
        network,
    }
}

export default connect(mapStateToProps, {})(withRouter(GoogleLoader));
