export const parseError = (error) => {
    let error_message = error.toString();

    if (error.response)
        if (error.response.data)
            error_message = error.response.data.body || "Unknown Error";
        else
            error_message = error.response.toString();


    return error_message;
};
