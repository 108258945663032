import * as actions from '../../actions/action-types';
import {
    authDataFromLocalStorage,
    deviceTokenFromLocalStorage,
    DATA_TYPE_JSON,
    DATA_TYPE_TEXT
} from "../../../react/consts/constants";

import {fetchLocalStorage, saveLocalStorageToState} from "../../actions/storage";

const storage = ({dispatch, getState}) => next => action => {
    if (action.type === actions.LOAD_LOCAL_STORAGE) {

        const dataToLoad = action.payload;

        dataToLoad.forEach(({key, type}) => {

            let data = null;
            if (type === DATA_TYPE_JSON) {
                try {
                    data = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
                } catch (e) {

                }
            } else if (type === DATA_TYPE_TEXT) {
                data = localStorage.getItem(key) || null;
            }

            dispatch(saveLocalStorageToState({[key]: data}));

        });
    } else if (action.type === actions.LOGIN_SUCCESS) {

        localStorage.setItem('user', JSON.stringify(action.payload));
        localStorage.setItem('token', action.payload.access_token);

        // Fetch item again to update application state
        dispatch(fetchLocalStorage(authDataFromLocalStorage));

    } else if (action.type === actions.LOGOUT_REQUEST) {

        localStorage.removeItem('user');
        localStorage.removeItem('token');

        // Fetch item again to update application state
        dispatch(fetchLocalStorage(authDataFromLocalStorage));
    } else if (action.type === actions.STORE_DEVICE_TOKEN) {

        localStorage.setItem('device_token', action.payload);
        dispatch(fetchLocalStorage(deviceTokenFromLocalStorage));

    } else {
        return next(action);
    }
};

export default storage;