import * as actions from "./action-types";
import {parseError} from "./parseAPIError";


export const getCampaignsSuccess = (payload) => ({
    type: actions.GET_ALL_CAMPAIGNS_SUCCESS,
    payload
});


export const getCampaignsFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: parseError(payload)
    });
};


export const getCampaignVideoUploadUrlSuccess = (payload) => ({
    type: actions.GET_CAMPAIGN_VIDEO_UPLOAD_URLS_SUCCESS,
    payload
});


export const getCampaignVideoUploadUrlFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: parseError(payload)
    });
};



export const createCampaignsSuccess = (payload) => ({
    type: actions.CREATE_CAMPAIGN_SUCCESS,
    payload
});

export const uploadVideoSuccess = (payload) => ({
    type: actions.UPLOAD_CAMPAIGN_VIDEO_SUCCESS,
    payload
});


export const createCampaignsFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: parseError(payload)
    });
};

export const successMessage = (payload) => ({
    type: actions.NOTISTACK_SUCCESS,
    payload: payload
});

export const getAllCampaignsApi = () => ({
    type: actions.API,
    payload: {
        url: 'get_all_campaigns',
        method: actions.API_POST,
        failure: (data) => getCampaignsFailed(data),
        success: (data) => getCampaignsSuccess(data),
        label: 'get_all_campaigns',
        authenticate: true
    }
});


export const videoUploadProgress = (video_id, progress) => ({
    type: actions.VIDEO_UPLOAD_PROGRESS,
    payload: {video_id: video_id, progress: progress}
});

export const videoUploaded = (payload) => ({
    type: actions.VIDEO_UPLOAD_COMPLETE,
    payload
});


export const getCampaignVideoUploadUrlApi = (payload) => ({
    type: actions.API,
    payload: {
        url: 'get_campaign_video_upload_links',
        data: payload,
        method: actions.API_POST,
        failure: (data) => getCampaignVideoUploadUrlFailed(data),
        success: (data) => getCampaignVideoUploadUrlSuccess(data),
        label: 'get_campaign_video_upload_links',
        authenticate: true
    }
});

export const createCampaignApi = (payload) => ({
    type: actions.API,
    payload: {
        url: 'create_new_campaign',
        data: payload,
        method: actions.API_POST,
        failure: (data) => createCampaignsFailed(data),
        success: (data) => createCampaignsSuccess(data),
        label: 'create_new_campaign',
        authenticate: true
    }
});

export const uploadCampaignVideo = (video_id, url, data, file) => ({
    type: actions.API,
    payload: {
        full_url: url,
        file,
        data,
        contentType:"multipart/form-data",
        uploadProgress: (progress) => videoUploadProgress(video_id, progress),
        method: actions.API_POST,
        failure: (data) => createCampaignsFailed(data),
        success: () => uploadVideoSuccess(video_id),
        label: 'upload_campaign_video_' + video_id,
        authenticate: false
    }
});
