import React from "react";
import axios from "axios";
import {
	LOGIN_FAILURE, LOGIN_SUCCESS,
	LOGIN_URL, SIGN_OUT_SUCCESS,
} from "../constants/constants";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state, isAuthenticated: true,
				username: action.payload["username"],
				fullName: action.payload["fullName"],
				role: action.payload["role"],
			};
		case LOGIN_FAILURE:
			return { ...state, isAuthenticated: false };
		case SIGN_OUT_SUCCESS:
			return {
				...state, isAuthenticated: false,
				username: "",
				fullName: "",
				role: "",
			};
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function UserProvider({ children }) {
	const [state, dispatch] = React.useReducer(userReducer, {
		isAuthenticated: !!localStorage.getItem("accessToken"),
		username: !!localStorage.getItem("username") ? localStorage.getItem("username") : "",
		fullName: !!localStorage.getItem("fullName") ? localStorage.getItem("fullName") : "",
		role: !!localStorage.getItem("role") ? localStorage.getItem("role") : "",
	});

	return (
	  <UserStateContext.Provider value={state}>
		  <UserDispatchContext.Provider value={dispatch}>
			  {children}
		  </UserDispatchContext.Provider>
	  </UserStateContext.Provider>
	);
}

function useUserState() {
	const context = React.useContext(UserStateContext);
	if (context === undefined) {
		throw new Error("useUserState must be used within a UserProvider");
	}
	return context;
}

function useUserDispatch() {
	const context = React.useContext(UserDispatchContext);
	if (context === undefined) {
		throw new Error("useUserDispatch must be used within a UserProvider");
	}
	return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
	setError(false);
	setIsLoading(true);
	const dataPayload = {
		"username": login,
		"password": password,
	};

	if (!!login && !!password) {
		axios.post(LOGIN_URL, dataPayload,
		).then(response => {
			setIsLoading(false);
			localStorage.setItem("accessToken", response.data["accessToken"]);
			localStorage.setItem("username", response.data["username"]);
			localStorage.setItem("fullName", response.data["fullName"]);
			localStorage.setItem("role", response.data["role"]);
			dispatch({ type: LOGIN_SUCCESS, payload: response.data });
			history.push("/");
		}).catch(err => {
			setIsLoading(false);
			setError(true);
			dispatch({ type: LOGIN_FAILURE });
		});

	}
}

function signOut(dispatch, history) {
	// localStorage.removeItem("accessToken");
	// localStorage.removeItem("username");
	// localStorage.removeItem("fullName");
	// localStorage.removeItem("role");
	// dispatch({ type: SIGN_OUT_SUCCESS });
	// history.push("/login");
}
