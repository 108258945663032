import * as actions from './action-types';

export const loginButtonPressed = (payload) => ({
    type: actions.LOGIN_BUTTON_SUBMIT_ACTION,
    payload
});

export const usernameChanged = (payload) => ({
    type: actions.LOGIN_USERNAME_CHANGED,
    payload
});


