import * as actions  from "../actions/action-types";

const DEFAULT_STATE = {
    activations: [],
    activation_form_valid: false,
    activation_form_errors: {},
    formValues: {}
};

export default function(state = DEFAULT_STATE, action){
    switch (action.type) {
        case actions.GET_ALL_ACTIVATIONS_SUCCESS:
            return {...state, activations: action.payload};
        case actions.SET_ACTIVATION_FORM_VALIDITY:
            return {...state, activation_form_valid: action.payload};
        case actions.SET_ACTIVATION_FORM_ERRORS:
            return {...state, activation_form_errors: action.payload};
        case actions.CLEAR_ACTIVATION_DATA:
            return DEFAULT_STATE;
        case actions.VALIDATE_ACTIVATION_FORM:
            return {...state, formValues: action.payload};
        default:
            return state;
    }
}
