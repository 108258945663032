import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {LoginPage,} from './login_scene_components';
import Typography from "@material-ui/core/Typography";
import Logo from '../../assets/images/marc1.png';
import MainLogo from '../../assets/images/magneticforce.png';
import {Box} from "@material-ui/core";
import useMedia from "../../components/hooks/useMedia";
import useWindowDimensions from '../../components/hooks/useWindowDimensions';
import LoginContent from "./LoginContent";

function LoginScene(props) {

    useEffect(() => {
        if (props.isAuthenticated) {
            props.history.push('/')
        }
    }, [props.isAuthenticated]);

    const screenSize = useMedia(
        // Media queries
        ['(min-width: 600px)', '(min-width: 450px)'],
        // Column counts (relates to above media queries by array index)
        ['large', 'small'],
        // Default column count
        'xsmall'
    );

    const {height, width} = useWindowDimensions();


    const renderMainLogo = () => {
        let textSize = '2em';

        if (height < 700)
            textSize = '1em';

        return (
            <React.Fragment>

                <Typography
                    style={{position: 'fixed', 'left': '5%', top: '3%'}}
                    color={"third"}
                    variant={"h4"}
                    align={"center"}>
                    <Box fontSize={textSize}>
                        {"Magnetic Force"}
                    </Box>
                </Typography>
            </React.Fragment>
        );
    };

    const renderLargeScreenLayout = () => {
        return (
            <React.Fragment>
                <img src={MainLogo} style={{position: 'fixed', 'left': '5%', top: '7%', width: '500px'}} alt="Logo"/>
            </React.Fragment>
        );
    };

    return (
        <LoginPage>
            {screenSize === 'large' ? renderLargeScreenLayout() : null}

            <LoginContent
                logo={Logo}
            />

        </LoginPage>

    );
}

function mapStateToProps(state) {
    const {user, token} = state.localStorage;

    return {
        isAuthenticated: !!(token),
    }
}

export default withRouter(connect(mapStateToProps, {})(withRouter(LoginScene)));

