import * as actions from '../../../actions/action-types';

const validationResult = (valid) => ({
    type: actions.SET_ACTIVATION_FORM_VALIDITY,
    payload: valid
});

const formErrorsAction = (formErrors) => ({
    type: actions.SET_ACTIVATION_FORM_ERRORS,
    payload: formErrors
});

export const validate_activation_form = (action) => {
    let formValues = action.payload;
    let formValid = true;
    let actions_to_dispatch = [];
    let formErrors = {};

    if (!formValues['activation_name'] || formValues['activation_name'].length < 3){
        formValid = false;
        formErrors['activation_name'] = "Activation Name must be at least 3 characters"
    }

    if (!formValues['campaign_id'] || formValues['campaign_id'].length < 3){
        formValid = false;
        formErrors['campaign_id'] = "Please select a campaign"
    }

    if (!formValues['stores'] || formValues['stores'].length < 1){
        formValid = false;
        formErrors['stores'] = "Please select at least 1 store"
    }

    if (formValues['send_first_sms'] && formValues['first_sms_text'].length < 1){
        formValid = false;
        formErrors['first_sms_text'] = 'Please enter a message';
    }

    if (formValues['send_follow_up_sms']){
        if (formValues['follow_up_sms_text'].length < 1){
            formValid = false;
            formErrors['follow_up_sms_text'] = 'Please enter a message';
        }
        let follow_up_sms_delay = parseInt(formValues['follow_up_sms_delay'].split(' ')[0]);
        if (!follow_up_sms_delay || follow_up_sms_delay < 1){
            formValid = false;
            formErrors['follow_up_sms_delay'] = 'Please enter a value greater than 0';
        }
    }

    if (formValues['send_sms']){
        if (formValues['activation_sms'].length < 1){
            formValid = false;
            formErrors['activation_sms'] = 'Please enter a message';
        }
        let sms_delay = parseInt(formValues['interval_between_messages'].split(' ')[0]);
        if (!sms_delay || sms_delay < 1){
            formValid = false;
            formErrors['interval_between_messages'] = 'Please enter a value greater than 0';
        }
    }

    let entry_period = parseInt(formValues['user_entry_period'].split(' ')[0]);
    if (!entry_period || entry_period < 1){
        formValid = false;
        formErrors['user_entry_period'] = 'Please enter a value greater than 0';
    }

    let max_user_entries = parseInt(formValues['maximum_user_entries']);
    if (!max_user_entries || max_user_entries < 1){
        formValid = false;
        formErrors['maximum_user_entries'] = 'Please enter a value greater than 0';
    }
    actions_to_dispatch.push(validationResult(formValid));
    actions_to_dispatch.push(formErrorsAction(formErrors));
    return actions_to_dispatch;
};
