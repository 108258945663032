import React from "react";
import Button from "@material-ui/core/Button";
import {PRIMARY_THEME_COLOR} from "../../consts/themes";
import PropTypes from "prop-types";

const RoundButtonStyle = {
    zIndex:"1",
    position:"fixed",
    bottom: "60px",
    right: "50px",
    width:"70px",
    height:"70px",
    border:"none",
    borderRadius:"50px",
    backgroundColor:PRIMARY_THEME_COLOR,
    color:"#ffffff",
    boxShadow: "0 6px 20px rgba(0,0,0,0.2)"
};

const FAB = (props) => {

    const onClick = (click) => {
        if (props.onClick)
            props.onClick(click);
    };

    return (
        <Button
            variant="outlined"
            style={RoundButtonStyle}
            onClick={(e)=>onClick(e)}>
            {props.buttonIcon}
        </Button>
    )

};

FAB.propTypes = {
    buttonIcon: PropTypes.object,
    onClick: PropTypes.func
};

export default FAB;
