import React, {useState} from "react";

import {connect, useDispatch} from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {resetUser} from "../../../../redux/actions/auth";


function Password(props) {

    const [password, setPassword] = useState(null);
    const dispatch = useDispatch();

    const onChange = (event) => {
        setPassword(event);
    };

    const onNext = () => {
        props.setPassword(password);
        props.onNext();
    };

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            if (password && password.length !== 0){
                onNext()
            }
            ev.preventDefault();
        }
    };

    return (
        <React.Fragment>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                id="password"
                error={password && password.length === 0}
                onKeyPress={(ev) => onKeyPress(ev)}
                helperText={'Enter your password'}
                onChange={(event)=>{onChange(event.target.value)}}
            />
            <Button
                style={{float: "right"}}
                onClick={() => {onNext()}}
                type="button"
                color={"secondary"}
                disabled={!password || password.length === 0}
                variant="contained">
                Login
            </Button>

            <Button
                style={{float: "left", textTransform: 'none'}}
                onClick={() => {props.onBack()}}
                type="button"
                color={"primary"}
                disabled={false}
                variant="text">
                {props.username ? (props.username !== '' ? "Not " + props.username + "?" : '') : '' }
            </Button>

            {props.username ? (props.username !== '' ?
                <Button
                    style={{float: "left", textTransform: 'none'}}
                    onClick={() => {dispatch(resetUser({"username":props.username}))}}
                    type="button"
                    color={"primary"}
                    disabled={false}
                    variant="text">
                    Forgot your password?
                </Button>
                    : null) : null}

        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(Password);


