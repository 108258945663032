import styled, {css, keyframes} from "styled-components";
import {PRIMARY_THEME_COLOR} from '../../consts/themes';

export const Loader = styled.div`
    background-color: #ffffffe0;
    margin-bottom: -1px;
    max-height: 3px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
`;

export const LoaderBar = styled.div`
    width: 40%;
    background-color: ${PRIMARY_THEME_COLOR};
    border-radius: 5px;
    height: 10px;
`;

export const EmptyDiv = styled.div`
    background-color: #ffffffe0;
    max-height: 3px;
    width: 100%;
`;
