import React, {useEffect} from 'react';

import {connect, useSelector} from 'react-redux';
import {Redirect, withRouter} from "react-router-dom";

import {logoutUser, routeToPath} from "../redux/actions/auth";
import {authDataFromLocalStorage} from './consts/constants';
import Log from '../modules/log';
import * as _ from "lodash";
import {fetchLocalStorage} from "../redux/actions/storage";

Log.info('Application Started');


const App = (props) => {

    const {user, token} = useSelector(state => state.localStorage);
    const {path} = useSelector(state => state);
    let isAuthenticated = !!token;

    useEffect(() => {
        if (!isAuthenticated){
            props.history.push('/login');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (!_.isEmpty(user)) {
            props.fetchLocalStorage(authDataFromLocalStorage);
        }
    }, [user]);

    if (isAuthenticated) {
        return (
            <Redirect to={path}/>
        );
    }

    return (<Redirect to="/login"/>);

};

export default withRouter(connect(null, {logoutUser, fetchLocalStorage, routeToPath})(App));
