import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone'
import {useDispatch} from "react-redux";
import UploadIcon from '@material-ui/icons/SaveAlt';

import {AllFilesContainer, Container, DropZoneContainer, FileListContainer,} from './file_upload_scene_components';
import * as actions from "../../../redux/actions/action-types";
import PropTypes from "prop-types";
import {Button} from "@material-ui/core";
import usePrevious from "../../components/hooks/usePrevious";
import getFileMd5 from "../../utils/md5_calculator";

const FileUploadScene = (props) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState(null);
    const prevFile = usePrevious({files});

    useEffect(() => {
        if (props.clearActiveFile)
            setFiles(null);
    }, [props.clearActiveFile]);

    useEffect(() => {
        if (props.onActiveFile) {
            props.onActiveFile(files)
        }

        if (prevFile)
            if (prevFile.files)
                prevFile.files.forEach(file => URL.revokeObjectURL(file.preview));


    }, [files]);

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();

        if (rejectedFiles.length > 0) {
            let failureMessage = "An error occurred when loading your file. Please ensure you have selected the correct file";
            if (rejectedFiles[0].type.indexOf(props.uploadFileType) < 0) {
                failureMessage = "Incorrect file type! Please select a video in mp4 format";
            }
            if (rejectedFiles[0].size > props.maxFileSize) {
                failureMessage = "You have selected a file that is too large! Please select a file less than 25MB";
            }

            if (rejectedFiles[0].size < props.minFileSize) {
                failureMessage = "You have selected a file that is too small! Please select a file greater than " + props.minFileSize.toString() + 'Bytes';
            }
            dispatch({
                type: actions.NOTISTACK_ERROR,
                payload: failureMessage
            });
            return;
        }

        Promise.all(acceptedFiles.map(async file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            md5: await getFileMd5(file)
        }))).then((res)=>{
            setFiles(res);
        });



        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = (e) => console.log('file reading has failed', e);

    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: props.uploadFileType,
        minSize: props.minFileSize,
        maxSize: props.maxFileSize,
        multiple: props.allowMultipleFiles,
        onDrop
    });

    const renderDropBoxMessage = () => {
        if (isDragActive)
            return (
                <React.Fragment>
                    <p>Drop the file here ...</p> :
                </React.Fragment>
            );

        return (
            <React.Fragment>
                <p>Drag and drop your video (mp4) file here, or click to select the file</p>
            </React.Fragment>
        );
    };

    const renderDropZone = () => {
        return (
            <DropZoneContainer
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <UploadIcon
                    fontSize={'large'}
                />
                {renderDropBoxMessage()}
            </DropZoneContainer>
        )
    };

    const renderVideoPreview = (file) => {
        return (
            <video width={'100%'} style={{maxHeight: '180px'}} controls>
                <source src={file.preview} type={file.type}/>
                Your browser does not support HTML5 video.
            </video>
        );
    };

    const renderFilesList = () => {
        const render_file_list = () => {
            return files.map((file, index) => {
                let video_file = false;
                if (file.type.indexOf('video/') === 0)
                    video_file = true;

                return (
                    <FileListContainer
                        key={index}
                    >
                        {video_file ? renderVideoPreview(file) : null}
                        {file.name}
                    </FileListContainer>
                );
            });
        };

        return (
            <AllFilesContainer>
                {render_file_list()}
                <br/>
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => {
                        setFiles(null)
                    }}
                >
                    Clear Files
                </Button>
            </AllFilesContainer>
        );
    };

    return (
        <Container>
            {files ? renderFilesList() : renderDropZone()}
        </Container>
    );
};

FileUploadScene.defaultProps = {
    uploadFileType: "*",
    minFileSize: 0,
    allowMultipleFiles: false
};

FileUploadScene.propTypes = {
    uploadFileType: PropTypes.string,
    onActiveFile: PropTypes.func,
    clearActiveFile: PropTypes.bool,
    maxFileSize: PropTypes.number,
    minFileSize: PropTypes.number,
    allowMultipleFiles: PropTypes.bool
};


export default FileUploadScene;
