import React, {useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {useSnackbar} from 'notistack';
import {resetNotistack} from "../../../redux/actions/notistack";
import DashboardRoute from "../../routes/dashboard/dashboard";
import LoginScene from "../../scenes/login_scene/login_scene";
import ForgotPasswordScene from "../../scenes/forgot_password_scene/forgot_password_scene";
import SignupScene from "../../scenes/signup_scene/signup_scene";
import App from "../../app";
import { LayoutProvider } from "../../context/LayoutContext";
import {UserProvider} from "../../context/UserContext";

function ApplicationBase(props) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(()=>{
        switch (props.notistack.state) {
            case 'success':
                enqueueSnackbar(props.notistack.message, {
                    variant: 'success',
                    key: new Date().getTime() + Math.random()
                });
                props.resetNotistack();
                break;

            case 'error':
                enqueueSnackbar(props.notistack.message, {
                    variant: 'error',
                    key: new Date().getTime() + Math.random()
                });
                props.resetNotistack();
                break;

            case 'warning':
                enqueueSnackbar(props.notistack.message, {
                    variant: 'warning',
                    key: new Date().getTime() + Math.random()
                });
                props.resetNotistack();
                break;

            case 'info':
                enqueueSnackbar(props.notistack.message, {
                    variant: 'info',
                    key: new Date().getTime() + Math.random()
                });
                props.resetNotistack();
                break;
        }
    },[props.notistack]);


    useEffect(() => {
        if (!props.isAuthenticated){
            props.history.push('/login');
        }
    }, [props.isAuthenticated]);

    return (
        <LayoutProvider>
            <UserProvider>
                <Switch>
                    <Route path="/dashboard" render={props=><DashboardRoute {...props}/>}/>
                    <Route path="/login" render={props=><LoginScene  {...props}/>}/>
                    <Route exact path="/" render={props=><App  {...props}/>}/>
                </Switch>
            </UserProvider>
        </LayoutProvider>

    );

}
function mapStateToProps(state) {
    const {user, token} = state.localStorage;
    const {notistack} = state;

    return {
        isAuthenticated: !!(token),
        notistack,
        user,
    }
}

export default withRouter(connect(mapStateToProps, {resetNotistack})(ApplicationBase));
