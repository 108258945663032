import * as actions  from "../actions/action-types";

const DEFAULT_STATE = {
    loginFailed: false,
    fingerprintLoginFailed: false,
    emailValid: null
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.LOGIN_FAILURE:
            return { ...state, loginFailed: true};

        case actions.LOGIN_FAILURE_RESET:
            return { ...state, loginFailed: false, fingerprintLoginFailed: false};
        case actions.EMAIL_VALID:
            return { ...state, emailValid: action.payload};
        default:
            return state;
    }
}
