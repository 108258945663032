import React from 'react';
import {connect, Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {MAIN_THEME} from "./consts/themes";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {fetchLocalStorage} from "../redux/actions/storage";
import {authDataFromLocalStorage} from "./consts/constants";
import {SnackbarProvider} from 'notistack';
import ApplicationBase from './components/ApplicationBase/application_base';

function Root(props) {
    props.fetchLocalStorage(authDataFromLocalStorage);

    return (
        <Provider store={props.store}>
            <BrowserRouter basename={"/"}>
                <ThemeProvider theme={MAIN_THEME}>
                    <SnackbarProvider
                        autoHideDuration={4000}
                        maxSnack={3}>
                        <ApplicationBase/>
                    </SnackbarProvider>
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps, {fetchLocalStorage})(Root);
