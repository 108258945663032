import styled from "styled-components";

export const FormContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
`;

export const LayoutContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;


export const PaperContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  overflow-y: auto;
    
`;

export const paperStyle = {
    topMargin: "10px",
    padding: "1em",
    justifyContent: "center",
    width: "70%",
    overflowY: "auto"

};

export const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
`;
