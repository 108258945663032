import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ForgotPasswordContent from "./ForgotPasswordContent";
import Loader from '../../components/Loader/google_loader';
import {
    ForgotPasswordPage,
    Container,
    Content,
} from './forgot_password_scene_components';

function ForgotPasswordScene(props) {

    const [path, setPath] = useState(props.path);

    useEffect(()=>{
        if (props.isAuthenticated){
            props.history.push('/')
        }
    },[props.isAuthenticated]);

    useEffect(()=>{
        if (props.path !== path){
            setPath(props.path);
            props.history.push(props.path);
            // window.location.reload();
        }
    },[props.path]);

    return(
        <ForgotPasswordPage>
            <Container>
                <Loader/>
                <Content>
                    <ForgotPasswordContent/>
                </Content>
            </Container>
        </ForgotPasswordPage>

    );
}

function mapStateToProps(state) {
    const {user, token} = state.localStorage;
    const {path} = state;

    return {
        path,
        isAuthenticated: !!(token),
    }
}

export default withRouter(connect(mapStateToProps, {})(ForgotPasswordScene));

