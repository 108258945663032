import React, { useState, useEffect } from "react";
import { Button, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { setTitle, useLayoutDispatch } from "../../context/LayoutContext";
import FullscreenSpinner from "../../components/Loader/fullscreen_spinner";
import {
    FormContainer,
    LayoutContainer,
    PaperContainer,
    paperStyle,
    ButtonContainer

} from './create_campaign_form_components';
import FileUploadScene from '../file_upload_scene/file_upload_scene';
import {withRouter} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {CampaignsContainer} from "../view_campaigns_scene/campaigns_components";
import * as actions from "../../../redux/actions/action-types";

const today = new Date();
const yesterday = new Date();
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);

function CreateCampaignForm(props) {
    const layoutDispatch = useLayoutDispatch();
    const [campaignName, setCampaignName] = useState("");
    const [activeFile, setActiveFile] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const networkActive = useSelector(state => state.network);
    const dispatch = useDispatch();

    const path = useSelector(state => state.path);

    useEffect(()=>{
        if (isLoaded){
            props.history.push(path)
        }
        else {
            dispatch({
                type: actions.SET_APPLICATION_PATH,
                payload:'/dashboard/view_campaigns/create_campaign'
            });
            setIsLoaded(true);
        }
    }, [path]);


    useEffect(() => {
        setTitle(layoutDispatch, "Create a Campaign");
    }, []);

    const checkInputValid = () => {
        return (campaignName.length > 3 && activeFile && activeFile.length >= 1);
    };

    const handleSubmit = (type) => () => {
        let videos = [];
        activeFile.forEach((file)=>{
            videos.push(file['md5'])
        });

        const payload = {
            "campaign_name": campaignName,
            "videos": videos
        };

        if (type === "add") {
            dispatch({
                type: actions.GET_CAMPAIGN_VIDEO_UPLOAD_LINKS,
                payload
            });
            dispatch({
                type: actions.NEW_CAMPAIGN_VIDEOS,
                payload:activeFile
            });
        }
    };
    return (
        <FormContainer>
            <FullscreenSpinner isLoading={!_.isEmpty(networkActive)}/>
            <LayoutContainer>
                <PaperContainer>
                    <Paper style={paperStyle} elevation={1}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    required
                                    id="Campaign Name"
                                    name="Campaign Name"
                                    label="Enter the Campaign Name"
                                    onChange={e => setCampaignName(e.target.value)}
                                    fullWidth
                                />
                            </Grid>


                        </Grid>
                        <Grid container direction="column">

                            <Grid style={{ paddingTop: "10px" }} item>
                                <FileUploadScene
                                    uploadFileType={"video/mp4"}
                                    onActiveFile={(file)=>{setActiveFile(file)}}
                                    maxFileSize={26214400}
                                    allowMultipleFiles={false}
                                />
                            </Grid>
                            <Grid item >
                                <ButtonContainer>
                                    <Button style={{}} variant="contained" color="primary" onClick={()=>{props.history.push('/dashboard/view_campaigns');}}
                                            fullWidth
                                    >
                                        Cancel
                                    </Button>
                                    <Button style={{}} variant="contained" color="secondary" onClick={handleSubmit("add")}
                                            fullWidth
                                            disabled={!checkInputValid()}
                                    >
                                        Submit
                                    </Button>
                                </ButtonContainer>

                            </Grid>


                        </Grid>
                    </Paper>
                </PaperContainer>
            </LayoutContainer>
        </FormContainer>
    );
}
export default withRouter(connect(null, {})(CreateCampaignForm));
