import React from 'react';
import BaseLayout from '../../components/base_layout/base_layout';
import CreatActivationForm from './create_activation_form';

const CreateActivationScene = (props) => {

    return (
        <BaseLayout>
            <CreatActivationForm/>
        </BaseLayout>
    );

};

export default CreateActivationScene
