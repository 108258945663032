import { combineReducers } from "redux";
import { reducer as ui } from 'redux-ui';
import activations from "./activations";
import campaigns from "./campaigns";
import login from './login';
import localStorage from "./storage";
import network from './network';
import notistack from './notistack';
import path from './path';
import stores from "./stores";

const rootReducer = combineReducers({
    activations,
    campaigns,
    localStorage,
    login,
    network,
    notistack,
    path,
    stores,
    ui
});

export default rootReducer;
