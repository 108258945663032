import * as actions  from "../actions/action-types";

const DEFAULT_STATE = '/dashboard/overview';

export default function(state = DEFAULT_STATE, action){
    switch (action.type) {
        case actions.SET_APPLICATION_PATH:
            return action.payload;

        default:
            return state;
    }
}
