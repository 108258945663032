import * as actions  from "../actions/action-types";

const DEFAULT_STATE = {
    campaigns: [],
    new_campaign_post_urls: {},
    new_campaign_videos: [],
    upload_progress: {},
    campaign_to_create: {}
};

let progress;

    export default function(state = DEFAULT_STATE, action){
    switch (action.type) {
        case actions.GET_ALL_CAMPAIGNS_SUCCESS:
            let user = JSON.parse(localStorage.getItem('user'))
            let suborganizations = user['suborganization']
            let suborganizations_ids = []
            let allowed_campaigns = []
            for (var suborganization of suborganizations) {
                suborganizations_ids.push(suborganization['suborganization_id'])
            }
            for (var campaign of action.payload) {
                if (campaign.hasOwnProperty('suborganization') && suborganizations_ids.includes(campaign['suborganization']['uuid'])){
                    allowed_campaigns.push(campaign)
                }
            }
            return {...state, campaigns: allowed_campaigns};
        case actions.CREATE_CAMPAIGN_SUCCESS:
            return {...state, new_campaign_post_urls: action.payload};
        case actions.NEW_CAMPAIGN_VIDEOS:
            return {...state, new_campaign_videos: action.payload};
        case actions.VIDEO_UPLOAD_PROGRESS:
            progress = {...state.upload_progress};
            progress[action.payload['video_id']] = {};
            progress[action.payload['video_id']]['loaded'] = action.payload['progress']['loaded'];
            progress[action.payload['video_id']]['total'] = action.payload['progress']['total'];
            return {...state, upload_progress: progress};
        case actions.VIDEO_UPLOAD_COMPLETE:
            progress = {...state.upload_progress};
            delete progress[action.payload];
            return {...state, upload_progress: progress};
        case actions.GET_CAMPAIGN_VIDEO_UPLOAD_LINKS:
            return {...state, campaign_to_create: action.payload};
        case actions.CLEAR_CAMPAIGN_DATA:
            return DEFAULT_STATE;
        default:
            return state;
    }
}
