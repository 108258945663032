import React, {useEffect,useState} from 'react';
import BaseLayout from '../../components/base_layout/base_layout';
import CreatCampaignForm from './create_campaign_form';

const CreateCampaignScene = (props) => {

    return (
        <BaseLayout>
            <CreatCampaignForm/>
        </BaseLayout>
    );

};

export default CreateCampaignScene
