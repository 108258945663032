import React from 'react';
import Route from "react-router-dom/es/Route";
import {Switch, withRouter} from "react-router-dom";
import QuickStartScene from '../../scenes/quick_start_scene/quick_start_scene';
import CreateCampaignScene from "../../scenes/create_campaign_scene/create_campaign_scene";
import CreateActivationScene from "../../scenes/create_activation_scene/create_activation_scene";
import ViewCampaignsScene from "../../scenes/view_campaigns_scene/view_campaigns_scene";
import ViewActivationsScene from "../../scenes/view_activations_scene/view_activations_scene";
import {Redirect, Router} from "react-router";

const Dashboard = ({match, history}) => (
    <Router history={history}>
            <Switch>
                    <Route path={`${match.url}/overview`} component={QuickStartScene}/>
                    <Route path={`${match.url}/view_campaigns/create_campaign`} component={CreateCampaignScene}/>
                    <Route path={`${match.url}/view_campaigns`} component={ViewCampaignsScene}/>
                    <Route path={`${match.url}/view_activations/create_activation`} component={CreateActivationScene}/>
                    <Route path={`${match.url}/view_activations`} component={ViewActivationsScene}/>
                    <Redirect to="/dashboard/overview" />
            </Switch>
    </Router>

);

export default withRouter(Dashboard);
