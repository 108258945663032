import * as actions from "./action-types";
import {parseError} from "./parseAPIError";

export const getAllStoresSuccess = (payload) => ({
    type: actions.GET_ALL_STORES_SUCCESS,
    payload
});


export const getAllStoresFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: parseError(payload)
    });
};

export const getAllStoresApi = () => ({
    type: actions.API,
    payload: {
        url: 'get_all_stores',
        method: actions.API_GET,
        failure: (data) => getAllStoresFailed(data),
        success: (data) => getAllStoresSuccess(data),
        label: 'get_all_stores',
        authenticate: true
    }
});
