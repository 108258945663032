import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SignupContent from "./SignupContent";
import Loader from '../../components/Loader/google_loader';
import {
    SignupPage,
    Container,
    Content,
} from './signup_scene_components';

function SignupScene(props) {

    const [path, setPath] = useState(props.path);

    useEffect(()=>{
        if (props.isAuthenticated){
            props.history.push('/')
        }
    },[props.isAuthenticated]);

    useEffect(()=>{
        if (props.path !== path){
            setPath(props.path);
            props.history.push(props.path);
        }
    },[props.path]);

    return(
        <SignupPage>
            <Container>
                <Loader/>
                <Content>
                    <SignupContent/>
                </Content>
            </Container>
        </SignupPage>

    );
}

function mapStateToProps(state) {
    const {user, token} = state.localStorage;
    const {path} = state;

    return {
        path,
        isAuthenticated: !!(token),
    }
}

export default withRouter(connect(mapStateToProps, {})(SignupScene));

