import React, {useState} from 'react';
import './switch.css';
import styled from "styled-components";
import Switch from '@material-ui/core/Switch';

const SwitchComponent = ({isOn, onChange, label, index}) => {
    const [checked, setChecked] = useState(isOn ? isOn : false);

    const onSelect = (data) => {
        setChecked(data);
        if (onChange)
            onChange(data)
    };

    return (
        <SwitchContainer>
            <Label>{label}</Label>
            <Switch
                checked={checked}
                onChange={() => setChecked(prevState => onSelect(!prevState))}
                name={index}
                color={"primary"}/>

        </SwitchContainer>
    );
};

export default SwitchComponent;

const SwitchContainer = styled.div`
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    width: 100%;
    align-items: center;
`;

const Label = styled.h4`
  color: #808080;
`;
