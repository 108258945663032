import React from 'react';
import Grid from "@material-ui/core/Grid";
import {gridStyleFlex, paperStyle, selectStyling} from "../create_activation_form_components";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "../../../components/ReactSelectAll/ReactSelectAll";
import {period_select_options} from "../../../consts/constants";
import {selectError, textError, textErrorMessage} from "../../../utils/errorHandlers";
import {useSelector} from "react-redux";

const GenerateEntryPeriod = ({onInputChange, touched}) => {
    const form_values = useSelector(state => state.activations.formValues);
    const formErrors = useSelector(state => state.activations.activation_form_errors);

    const onEntryPeriodChange = (value) => {
            let entry_value = form_values['user_entry_period'].split(' ')[0];
            onInputChange("user_entry_period", entry_value + " " + value);
        };

        const onEntryValueChange = (value) => {
            let entry_period = form_values['user_entry_period'].split(' ')[1];
            onInputChange("user_entry_period", value + " " + entry_period);
        };

    return (
            <Grid style={gridStyleFlex} item>
                <Paper style={paperStyle} elevation={2}>

                    <div style={{display: "flex", flexDirection:"row"}}>
                        <div style={{flex: "1", textAlign:"center"}}>
                            <TextField
                                error={textError(touched, formErrors, 'maximum_user_entries')}
                                helperText={textErrorMessage(touched, formErrors, 'maximum_user_entries')}
                                required
                                id="maximum_user_entries"
                                name="maximum_user_entries"
                                label="Enter the number of entries allowed"
                                type={"number"}
                                value={form_values['maximum_user_entries']}
                                onChange={e => onInputChange('maximum_user_entries', e.target.value)}
                                fullWidth
                                inputProps={{ min: "1" }}
                            />
                        </div>
                        <div style={{padding:"10px"}}>
                            {"per"}
                        </div>
                        <div style={{flex: "0.5", textAlign:"center"}}>
                            <TextField
                                error={textError(touched, formErrors, 'user_entry_period')}
                                helperText={textErrorMessage(touched, formErrors, 'user_entry_period')}
                                id="entry_period_value"
                                name="entry_period_value"
                                label="Enter the period"
                                type={"number"}
                                value={form_values['user_entry_period'].split(' ')[0]}
                                onChange={e => onEntryValueChange(e.target.value)}
                                fullWidth
                                inputProps={{ min: "1" }}
                            />
                        </div>
                        <div style={{flex: "0.5", textAlign:"center", marginLeft: "10px"}}>
                            <Select
                                errorText={selectError(touched, formErrors, 'entry_period')}
                                styles={selectStyling}
                                options={period_select_options}
                                isSearchable={false}
                                defaultValue={period_select_options[2]}
                                placeholder={"Please select the period"}
                                name={"entry_period"}
                                id={"entry_period"}
                                onChange={event => onEntryPeriodChange(event.value)}
                            />
                        </div>
                    </div>
                </Paper>
            </Grid>
        );
};

export default GenerateEntryPeriod;