import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../../redux/actions/action-types";
import styled from "styled-components";
import {toTitleCase} from "../../components/HumanReadableConverters/title_case_converter";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import {cancelActivationApi} from "../../../redux/actions/activations";
import CancelIcon from '@material-ui/icons/Cancel';

function ActivationsTable(props) {
    const dispatch = useDispatch();
    const [pageRows, setPageRows] = useState(10);

    const activations = useSelector(state => state.activations.activations);
    const [columns, setColumns] = React.useState([
        {
            title: 'Activation Name',
            field: 'activation_name',
        },
        {
            title: 'Campaign Name',
            field: 'campaign_name',
        },
        {
            title: 'Activation Active',
            field: 'active',
            render: rowData => (rowData.active ? "True" : "False")
        },
        {
            title: 'Activation Status',
            field: 'status'
        },
        {
            title: 'Created By',
            field: 'created_by',
            render: rowData => (rowData.created_by ? rowData.created_by : "Unknown")
        },
        {
            title: 'Created At',
            field: 'created_at',
            render: (rowData => {
                let created_at_time = rowData.created_at ? rowData.created_at : null;
                let time;
                if (created_at_time)
                    time = new Date(created_at_time);
                else
                    time = new Date();

                return time.toLocaleDateString() + " " + time.toLocaleTimeString()

            })
        },
        {
            title: 'Actions',
            render: (rowData => {
                let canBeCancelled = rowData.status !== "CANCELLED" && rowData.status !== "COMPLETE" && rowData.status !== "SCHEDULED";
                if (!canBeCancelled)
                    return null;
                return <CancelIcon onClick={()=>{dispatch(cancelActivationApi({
                    activation_name: rowData.activation_name,
                    campaign_id: rowData.campaign_id,
                    activation_id: rowData.activation_id
                }))}}/>

            })
        }
    ]);

    const sub_columns = [
        {
            title: 'Field Name',
            field: 'column',
        },
        {
            title: 'Value',
            field: 'value',
            render: rowData => Array.isArray(rowData.value) ? rowData.value.map(displayChips) : rowData.value
        },
    ];

    useEffect(() => {
        dispatch({
            type: actions.GET_ALL_ACTIVATIONS
        });
    }, []);

    const renderDetails = (rowData) => {

        return Object.keys(rowData).reduce((result, key) => {
            if (['tableData', 'organization', 'suborganization'].indexOf(key) === -1){
                let value = rowData[key] === true ? "True" : rowData[key] === false ? "False" : rowData[key];
                // if (Array.isArray(value)){
                //     value = value.join(', ')
                // }
                result.push({
                    column: toTitleCase(key.replace(/_/g, ' ')),
                    value: value,
                });
            }
                return result;
        }, []);
    };

    const displayChips = (data, index) => {
        return (
            <Tooltip title={data} key={index}>
                <Chip
                    label={data}
                    color="default"
                />
            </Tooltip>
        );

    };

    const renderDetailsTable = (rowData) => {
        return (
            <MaterialTable
                title="Details"
                columns={sub_columns}
                style={{width: "100%"}}
                data={renderDetails(rowData)}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [25],
                    exportButton: false,
                    grouping: false,
                    filtering: false
                }}
            />
        );
    };

    return (
        <MaterialTable
            title="Activations"
            columns={columns}
            style={{width: "100%"}}
            data={activations}
            onChangeRowsPerPage={(data) => {
                setPageRows(data)
            }}
            // actions={[
            //     (rowData) => {
            //         return (['CANCELLED', 'COMPLETE'].indexOf(rowData.status) === -1)
            //             ? { icon: 'delete', disable: true, tooltip: 'Disable/End Activation', onClick: (event, rowData) => dispatch(cancelActivationApi(rowData)) }
            //             : null
            //     }
            // ]}
            options={{
                pageSize: pageRows,
                pageSizeOptions: [5, 10, 15],
                exportButton: true,
                grouping: true,
                filtering: true
            }}
            detailPanel={rowData => {return (renderDetailsTable(rowData))}}
        />
    );
}

function mapStateToProps(state) {
    const {batches} = state;
    return {
        batches
    }
}

export default connect(mapStateToProps, {})(withRouter(ActivationsTable));

const DetailsItem = styled.div`
      display: flex;
      flex-direction: row;
`;

const DetailsField = styled.div`
      flex: 1;
`;
