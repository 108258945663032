import { makeStyles } from "@material-ui/styles";
import {PRIMARY_THEME_COLOR} from "../../../../consts/themes";

export default makeStyles(theme => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.light,
    },
  },
  linkOpen: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.light,
    },
  },
  linkClosed: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.light,
    },
  },
  linkActiveOpen: {
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px',
    backgroundColor: PRIMARY_THEME_COLOR,
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  linkIcon: {
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },
  linkIconActiveOpen: {
    color: '#fff',
  },
  linkIconActiveClosed: {
    color: PRIMARY_THEME_COLOR,
  },
  linkText: {
    padding: 0,
    color: theme.palette.text.secondary + "CC",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 16,
  },
  linkTextActive: {
    color: '#fff',
  },
  linkTextActiveOpen: {
    color: '#fff',
  },
  linkTextActiveClosed: {
    color: PRIMARY_THEME_COLOR,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 30,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
  listItemTextOpen:{
    fontSize:'1rem',//Insert your required size
  },
  listItemTextClosed:{
    fontSize:'0.7em',//Insert your required size
  }
}));
