import * as actions from '../../actions/action-types';
import {validate_registration_form} from '../components/form_validators/registration_form_validator'
import {validate_reset_password_form} from '../components/form_validators/reset_password_form_validator';
import {are_fields_empty, emailCheck} from '../components/form_validators/validators';
import {
    loginUser,
    registerUser, resetUser,
    routeToPath
} from '../../actions/auth';
import {
    createCampaignApi,
    getAllCampaignsApi, getCampaignVideoUploadUrlApi,
    uploadCampaignVideo,
    videoUploaded
} from "../../actions/campaigns";
import {createActivationApi, getAllActivationsApi} from "../../actions/activations";
import {route_to_path} from "../../actions/ui";
import {getAllStoresApi} from "../../actions/stores";
import {validate_activation_form} from "../components/form_validators/activation_form_validator";

const ADMIN_PORTAL_ACTIONS = [
    actions.LOGIN_USERNAME_CHANGED,
    actions.LOGIN_BUTTON_SUBMIT_ACTION,
    actions.SIGNUP_BUTTON_SUBMIT_ACTION,
    actions.SIGNUP_SUCCESS,
    actions.RESET_PASSWORD_BUTTON_SUBMIT_ACTION,
    actions.PASSWORD_RESET_FAILURE,
    actions.PASSWORD_RESET_SUCCESS,
    actions.ID_NUMBER_CHANGED,
    actions.REGISTRATION_FORM_CHANGED,
    actions.REGISTRATION_FORM_SUBMIT,
    actions.GET_ALL_CAMPAIGNS,
    actions.GET_ALL_CAMPAIGNS_SUCCESS,
    actions.GET_ALL_ACTIVATIONS,
    actions.GET_ALL_ACTIVATIONS_SUCCESS,
    actions.GET_CAMPAIGN_VIDEO_UPLOAD_LINKS,
    actions.GET_CAMPAIGN_VIDEO_UPLOAD_URLS_SUCCESS,
    actions.CREATE_CAMPAIGN,
    actions.CREATE_CAMPAIGN_SUCCESS,
    actions.NEW_CAMPAIGN_VIDEOS,
    actions.UPLOAD_CAMPAIGN_VIDEO_SUCCESS,
    actions.VIDEO_UPLOAD_PROGRESS,
    actions.GET_ALL_STORES,
    actions.VALIDATE_ACTIVATION_FORM,
    actions.ACTIVATION_FORM_SUBMIT,
    actions.CREATE_ACTIVATION_SUCCESS,
    actions.CANCEL_ACTIVATION_FAILED,
    actions.CANCEL_ACTIVATION_SUCCESS
];

const portal_interface = ({dispatch, getState}) => next => action => {

    const dispatch_all = (actions) => {
        if (actions.length !== 0)
        {
            for (let i = 0; i < actions.length ; i++) {
                if ('type' in actions[i] && 'payload' in actions[i]){
                    dispatch(actions[i]);
                }
            }
        }
    };

    if (ADMIN_PORTAL_ACTIONS.indexOf(action.type) === -1) {
        return next(action);
    }

    next(action);

    if (action.type === actions.SIGNUP_SUCCESS) {
        dispatch(routeToPath('/login'));
        return;
    }

    if (action.type === actions.LOGIN_USERNAME_CHANGED) {
        let invalidEmail = emailCheck(action.payload);
        dispatch({
            type: actions.EMAIL_VALID,
            payload: invalidEmail
        });
        return;
    }

    if (action.type === actions.LOGIN_BUTTON_SUBMIT_ACTION) {
        dispatch(loginUser(action.payload));
        return;
    }

    if (action.type === actions.SIGNUP_BUTTON_SUBMIT_ACTION) {
        let validation_result = validate_registration_form(action);

        if (validation_result){
            dispatch(validation_result);
            return;
        }

        dispatch(registerUser(action.payload));
        return;
    }

    if (action.type === actions.RESET_PASSWORD_BUTTON_SUBMIT_ACTION){
        let validation_result = validate_reset_password_form(action);

        if (validation_result){
            dispatch(validation_result);
            return;
        }

        dispatch(resetUser(action.payload));
        return;
    }

    if (action.type === actions.PASSWORD_RESET_SUCCESS){
        dispatch(routeToPath('/login'));
        return;
    }

    if (action.type === actions.GET_ALL_CAMPAIGNS){
        dispatch(getAllCampaignsApi());
        return;
    }

    if (action.type === actions.GET_ALL_ACTIVATIONS){
        dispatch(getAllActivationsApi());
        return;
    }

    if (action.type === actions.GET_CAMPAIGN_VIDEO_UPLOAD_LINKS){
        dispatch(getCampaignVideoUploadUrlApi(action.payload));
        return;
    }

    if (action.type === actions.GET_CAMPAIGN_VIDEO_UPLOAD_URLS_SUCCESS){
        let state = getState();
        for (let index in state.campaigns.new_campaign_videos){
            if (state.campaigns.new_campaign_videos[index]['md5'] in action.payload){
                let video_id = state.campaigns.new_campaign_videos[index]['md5'];
                let data = new FormData();
                for (let field in action.payload[video_id]['fields']){
                    data.append(field, action.payload[video_id]['fields'][field])
                }
                dispatch(uploadCampaignVideo(video_id,
                    action.payload[video_id]['url'], data, state.campaigns.new_campaign_videos[index]))
            }
        }
        return;
    }

    if (action.type === actions.VIDEO_UPLOAD_PROGRESS){
        if (action.payload['progress']['loaded'] === action.payload['progress']['total']){
            if (Object.keys(getState().campaigns.upload_progress).length === 1){
                dispatch(createCampaignApi(getState().campaigns.campaign_to_create));
            }
            dispatch(videoUploaded(action.payload.video_id));
        }
    }

    if (action.type === actions.CREATE_CAMPAIGN_SUCCESS){
        dispatch({
            type: actions.CLEAR_CAMPAIGN_DATA
        });
        dispatch({
            type: actions.NOTISTACK_SUCCESS,
            payload: "Campaign Created"
        });
        dispatch(route_to_path('/dashboard/view_campaigns'));
    }

    if (action.type === actions.GET_ALL_STORES){
        dispatch(getAllStoresApi())
    }

    if (action.type === actions.VALIDATE_ACTIVATION_FORM){
        let validation_result = validate_activation_form(action);
        dispatch_all(validation_result);
    }

    if (action.type === actions.ACTIVATION_FORM_SUBMIT){
        dispatch(createActivationApi(action.payload));
    }

    if (action.type === actions.CREATE_ACTIVATION_SUCCESS){
        dispatch({
            type: actions.CLEAR_CAMPAIGN_DATA
        });
        dispatch({
            type: actions.CLEAR_ACTIVATION_DATA
        });
        dispatch({
            type: actions.CLEAR_STORE_DATA
        });
        dispatch({
            type: actions.NOTISTACK_SUCCESS,
            payload: "Activation Created"
        });
        dispatch(route_to_path('/dashboard/view_activations'));
    }

    if (action.type === actions.CANCEL_ACTIVATION_SUCCESS){
        dispatch(getAllActivationsApi());
        dispatch({
            type: actions.NOTISTACK_SUCCESS,
            payload: "Activation Cancellation Requested"
        });
    }

    if (action.type === actions.CANCEL_ACTIVATION_FAILED){
        dispatch({
            type: actions.NOTISTACK_ERROR,
            payload: "Failed to request Cancellation"
        });
    }

};

export default portal_interface;
