export const ROOT_URL = __ROOT_URL__;
export const LOGIN_URL = __LOGIN_URL__;

export const DATA_TYPE_TEXT = "DATA_TYPE_TEXT";
export const DATA_TYPE_JSON = "DATA_TYPE_JSON";

export const authDataFromLocalStorage = [
    {
        key: "token",
        type: DATA_TYPE_TEXT
    },
    {
        key: "user",
        type: DATA_TYPE_JSON
    }
];

export const deviceTokenFromLocalStorage = [
    {
        key: "device_token",
        type: DATA_TYPE_TEXT
    }
];

export const period_select_options = [
    {
        'label': 'Minute/s',
        'value': 'minutes'
    },
    {
        'label': 'Hour/s',
        'value': 'hours'
    },
    {
        'label': 'Day/s',
        'value': 'days'
    },
    {
        'label': 'Week/s',
        'value': 'weeks'
    },
    {
        'label': 'Month/s',
        'value': 'months'
    },
    {
        'label': 'Year/s',
        'value': 'years'
    },
];

export const activationsForm = {
    "campaign_name":"",
    "campaign_id":"",
    "active":true,
    "stores":[],
    "send_first_sms": false,
    "first_sms_text":"",
    "send_follow_up_sms": false,
    "follow_up_sms_text":"",
    "follow_up_sms_delay":"30 minutes",
    "interval_between_messages":"14 days",
    "maximum_user_entries": 1,
    "send_sms": false,
    "activation_sms":"",
    "user_entry_period": "1 days",
    "ignore_proceeding_rules":true
};
