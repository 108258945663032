import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QuickStartCardIcon from './QuickStartCardIcon';
import CampaignIcon from '../../assets/images/digital-campaign.png';
import MarketingIcon from '../../assets/images/marketing.png';

import {
    QuickStartContainer,
} from './quick_start_scene_components';

import BaseLayout from '../../components/base_layout/base_layout';
import {setTitle, useLayoutDispatch} from "../../context/LayoutContext";

function QuickStartScene(props) {

    const layoutDispatch = useLayoutDispatch();

    useEffect(() => {
        setTitle(layoutDispatch, "Home");
    }, []);

    const handleNavBarClick = (clicked_key) => {
        props.history.push('/dashboard/' + clicked_key);
    };

    return(
        <BaseLayout>
            <QuickStartContainer>
                <QuickStartCardIcon
                    title={"Create a new campaign"}
                    icon={CampaignIcon}
                    description={"Upload a video and set a campaign name to start tracking your customers response against a specific campaign"}
                    onClick={()=>{handleNavBarClick('view_campaigns/create_campaign')}}
                />
                <QuickStartCardIcon
                    title={"Create an Activation"}
                    icon={MarketingIcon}
                    description={"Set a date/time to start running your campaigns"}
                    onClick={()=>{handleNavBarClick('view_activations/create_activation')}}
                />
            </QuickStartContainer>
        </BaseLayout>
    );
}

function mapStateToProps(state) {
    const {} = state;
    return {
    }
}

export default withRouter(connect(mapStateToProps, {})(QuickStartScene));

