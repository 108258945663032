//urls
export const ROOT_URL = "https://686y7l57hg.execute-api.eu-west-2.amazonaws.com/prod";
export const LOGIN_URL = ROOT_URL + "/login";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

export const SET_TITLE = "SET_TITLE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
