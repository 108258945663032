import React from "react";
import { SET_TITLE, TOGGLE_SIDEBAR } from "../constants/constants";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return { ...state, isSidebarOpened: !state.isSidebarOpened };
		case SET_TITLE:
			return { ...state, appBarHeaderTitle: action.payload };

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function LayoutProvider({ children }) {
	const [state, dispatch] = React.useReducer(layoutReducer, {
		isSidebarOpened: true,
		appBarHeaderTitle: "",
	});
	return (
	  <LayoutStateContext.Provider value={state}>
		  <LayoutDispatchContext.Provider value={dispatch}>
			  {children}
		  </LayoutDispatchContext.Provider>
	  </LayoutStateContext.Provider>
	);
}

function useLayoutState() {
	var context = React.useContext(LayoutStateContext);
	if (context === undefined) {
		throw new Error("useLayoutState must be used within a LayoutProvider");
	}
	return context;
}

function useLayoutDispatch() {
	var context = React.useContext(LayoutDispatchContext);
	if (context === undefined) {
		throw new Error("useLayoutDispatch must be used within a LayoutProvider");
	}
	return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, setTitle };

// ###########################################################
function toggleSidebar(dispatch) {
	dispatch({
		type: TOGGLE_SIDEBAR,
	});
}

function setTitle(dispatch, title) {
	dispatch({
		type: SET_TITLE,
		payload: title,
	});
}
