import React, {useEffect} from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter,
} from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import {ContentContainer} from "./syled_components";
import {useSelector} from "react-redux";



// context
function Layout(props) {
    var classes = useStyles();
    return (
        <div className={classes.root}>
            <>
                <Header history={props.history} />
                <Sidebar history={props.history} />
                <div className={classes.fakeToolbar} />
                <ContentContainer>
                    {props.children}
                </ContentContainer>
            </>
        </div>
    );
}

export default withRouter(Layout);
