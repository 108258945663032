import React, {useEffect, useState, useRef} from 'react';
import Container from '@material-ui/core/Container';
import {CssBaseline} from "@material-ui/core";
import useMedia from '../hooks/useMedia';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useSnackbar} from 'notistack';
import {resetNotistack} from "../../../redux/actions/notistack";
import { Animate, AnimateKeyframes } from 'react-simple-animate';
import GoogleLoader from './google_loader';
import {
    LoginContainer,
    PaperBox,
    Paper,
    SlideComponent,
    Loader,
    LoaderBar
} from './google_login_layout_components';
function GoogleLoginLayout(props) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loader_active, setLoaderActive] = useState(true);

    const screenSize = useMedia(
        // Media queries
        ['(min-width: 600px)', '(min-width: 450px)'],
        // Column counts (relates to above media queries by array index)
        ['large', 'small'],
        // Default column count
        'xsmall'
    );

    const slideDirection = {
        'fromLeft':[
            'transform: translateX(-500px)',
            'transform: translateX(0px)',
        ],
        'fromRight': [
            'transform: translateX(500px)',
            'transform: translateX(0px)',
        ]
    };

    useEffect(()=>{
        if (_.isEmpty(props.network)) {
            setLoaderActive(false);
            return;
        }
        setLoaderActive(true);
    },[props.network]);

    const renderContent = () => {
        return (
            <Paper
                screenSize={screenSize}
            >
                <img src={props.logo} style={{maxWidth: "50%", marginBottom: "15px"}}/>
                {props.staticTitle ? props.staticTitle : null}
                <AnimateKeyframes
                    play={true}
                    iterationCount="1"
                    direction="normal"
                    keyframes={slideDirection[props.slideDirection || 'fromLeft']}
                >
                    <SlideComponent id={"A2D24"}>
                        {props.children}
                    </SlideComponent>

                </AnimateKeyframes>


            </Paper>
        );
    };

    if (screenSize !== 'large') {
        return (
            <Container style={{height:"100vh", background: "#ffffffd9"}} component="main" maxWidth="xs">
                <GoogleLoader/>
                <CssBaseline/>
                {renderContent()}
            </Container>
        );
    }

    return (
        <LoginContainer>
            <div style={{width:"100%", minHeight:"50px", marginTop:"100px"}}>

            </div>
            <GoogleLoader/>
            <CssBaseline/>
            <PaperBox>
                {renderContent()}
            </PaperBox>
            <div style={{width:"100%", minHeight:"50px"}}/>
        </LoginContainer>
    );

}

function mapStateToProps(state) {
    const {notistack, network} = state;

    return {
        network,
        notistack,
    }
}

export default connect(mapStateToProps, {resetNotistack})(withRouter(GoogleLoginLayout));
