import * as actions from "./action-types";
import {parseError} from "./parseAPIError";

export const getActivationsSuccess = (payload) => ({
    type: actions.GET_ALL_ACTIVATIONS_SUCCESS,
    payload
});


export const getActivationsFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: parseError(payload)
    });
};


export const createActivationSuccess = (payload) => ({
    type: actions.CREATE_ACTIVATION_SUCCESS,
    payload
});

export const cancelActivationSuccess = (payload) => ({
    type: actions.CANCEL_ACTIVATION_SUCCESS,
    payload
});

export const cancelActivationFailed = (payload) => ({
    type: actions.CANCEL_ACTIVATION_FAILED,
    payload
});


export const createActivationFailed = (payload) => (dispatch) => {
    dispatch({
        type: actions.NOTISTACK_ERROR,
        payload: parseError(payload)
    });
};

export const validateActivationForm = (formValues) => (dispatch) =>  {
    dispatch({
        type: actions.VALIDATE_ACTIVATION_FORM,
        payload: formValues
    })
};


export const getAllActivationsApi = () => ({
    type: actions.API,
    payload: {
        url: 'get_all_activations',
        method: actions.API_POST,
        failure: (data) => getActivationsFailed(data),
        success: (data) => getActivationsSuccess(data),
        label: 'get_all_activations',
        authenticate: true
    }
});


export const createActivationApi = (data) => ({
    type: actions.API,
    payload: {
        url: 'create_new_activation',
        data,
        method: actions.API_POST,
        failure: (data) => createActivationFailed(data),
        success: (data) => createActivationSuccess(data),
        label: 'create_new_activation',
        authenticate: true
    }
});

export const cancelActivationApi = (data) => ({
    type: actions.API,
    payload: {
        url: 'cancel_activation',
        data,
        method: actions.API_POST,
        failure: (data) => cancelActivationFailed(data),
        success: (data) => cancelActivationSuccess(data),
        label: 'cancel_activation',
        authenticate: true
    }
});
