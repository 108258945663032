import {createMuiTheme} from "@material-ui/core/styles/index";

export const PRIMARY_THEME_COLOR = "#ea4f33";
export const SECONDARY_THEME_COLOR = "#873257";
export const THIRD_THEME_COLOR = "#ea4f33";
export const LOGO_COLOR = "#873257";

export const palette = [PRIMARY_THEME_COLOR, SECONDARY_THEME_COLOR, THIRD_THEME_COLOR];

export const MAIN_THEME = createMuiTheme({
    palette: {
        primary: {main: PRIMARY_THEME_COLOR},
        secondary: {main: SECONDARY_THEME_COLOR},
        third: {main: LOGO_COLOR},
        background: {
            main: PRIMARY_THEME_COLOR,
            light: THIRD_THEME_COLOR
        }
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiButton:{
            containedPrimary: {
                backgroundColor: SECONDARY_THEME_COLOR
            },
            containedSecondary: {
                backgroundColor: PRIMARY_THEME_COLOR
            }
        },
        MuiTextField:{

        }
    }
});
