import {addMinutesToDate} from "../../../utils/date_utils";

const today = new Date();

export const min_start_time = addMinutesToDate(today, 3);
export const min_end_time = addMinutesToDate(today, 6);

export const activationsFormAdjustments = {
    "activation_name":"",
    "start_time": min_start_time.toISOString(),
    "end_time": min_end_time.toISOString(),
};