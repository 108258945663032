import * as actions from './action-types';

export const startNetwork = (payload = 'global') => ({
    type: actions.START_NETWORK,
    payload
});

export const endNetwork = (payload = 'global') => ({
    type: actions.END_NETWORK,
    payload
});

export const route_to_path = (payload) => ({
    type: actions.SET_APPLICATION_PATH,
    payload
});