import React, {useEffect, useState} from "react";
import {
} from './login_scene_components';

import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import UsernameComponent from './components/username';
import PasswordComponent from './components/password';
import GoogleLoginLayout from "../../components/google_login_layout/google_login_layout";
import * as actions from "../../../redux/actions/action-types";

function LoginContent(props) {

    const [slideDirection, setSlideDirection] = useState('fromLeft');
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        username:null,
        password:null
    });

    const [loginStep, setLoginStep] = useState('username');

    const onFormChanged = (field, data) => {
        let temp_form_values = {...formValues};
        temp_form_values[field] = data;
        setFormValues(temp_form_values);
    };

    const onNext = () => {
        if (loginStep === 'username'){
            setLoginStep('password');
            setSlideDirection('fromRight');
        }
        if (loginStep === 'password'){
        }
    };

    const onBack = () => {
        if (loginStep === 'password'){
            setLoginStep('username');
            setSlideDirection('fromLeft');
        }
    };

    const submitFormValues = () => {
        if (formValues.username && formValues.password){
            dispatch({
                type: actions.LOGIN_BUTTON_SUBMIT_ACTION,
                payload: formValues
            });
        }
    };

    useEffect(()=>{
        submitFormValues();
    },[formValues]);

    const renderTitle = (text) => {
        return (
            <Typography
                color={"secondary"}
                variant={"h3"}
                align={"center"}>
                {text}
            </Typography>
        );
    };

    const renderLoginComponents = () => {
        if (loginStep === 'username')
            return (
                <UsernameComponent
                    onNext={()=>{onNext()}}
                    setUsername={(username)=>{onFormChanged('username', username)}}
                />
            );

        if (loginStep === 'password')
            return (
                <PasswordComponent
                    username={formValues.username || ''}
                    onNext={()=>{onNext()}}
                    onBack={()=>{onBack()}}
                    setPassword={(password)=>{onFormChanged('password', password)}}
                />
            );

        return null;
    };

    return (
        <React.Fragment>
            <GoogleLoginLayout
                key={slideDirection}
                logo={props.logo}
                slideDirection={slideDirection}
            >

                {renderLoginComponents()}

            </GoogleLoginLayout>

        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(LoginContent));


