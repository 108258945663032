import React, {useState} from "react";
import {
    WifiAdminFormBox,
    WifiAdminFormInputBox,
    WifiAdminFormAreaFieldSet,
    WifiAdminFormAreaLabel,
    PortalFormButton,
    FormAreaBottomLink,
    WifiAdminFormUL,
    WifiAdminFormLI, WifiAdminFormAreaBottom, WifiAdminBox
} from '../../components/doctor_portal_form/doctor_portal_form_components'

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {signupButtonPressed} from "../../../redux/actions/signup_scene";
function SignupContent(props) {

    const [formValues, setFormValues] = useState({});

    const onFormChanged = (field, data) => {
        let temp_form_values = {...formValues};
        temp_form_values[field] = data;
        setFormValues(temp_form_values);
    };

    return (
        <WifiAdminBox>
                <h1>Signup for WiFi Management</h1>
                <WifiAdminFormBox>
                    <WifiAdminFormAreaFieldSet>
                        <WifiAdminFormAreaLabel>Full Name</WifiAdminFormAreaLabel>
                        <WifiAdminFormInputBox
                            maxLength="128"
                            autofill={"none"}
                            name="full_name"
                            type="full_name"
                            onChange={(data)=>{onFormChanged("full_name", data.target.value)}}
                        />
                    </WifiAdminFormAreaFieldSet>
                    <WifiAdminFormAreaFieldSet>
                        <WifiAdminFormAreaLabel>Email</WifiAdminFormAreaLabel>
                        <WifiAdminFormInputBox
                            maxLength="128"
                            autofill={"none"}
                            name="email"
                            type="email"
                            onChange={(data)=>{onFormChanged("username", data.target.value)}}
                        />
                    </WifiAdminFormAreaFieldSet>

                    <WifiAdminFormAreaFieldSet>
                        <WifiAdminFormAreaLabel>Password</WifiAdminFormAreaLabel>
                        <WifiAdminFormInputBox id="password"
                           autofill={"none"}
                           name="password"
                           type="password"
                           onChange={(data)=>{onFormChanged("password", data.target.value)}}
                        />
                    </WifiAdminFormAreaFieldSet>

                    <WifiAdminFormAreaFieldSet>
                        <WifiAdminFormAreaLabel>Confirm Password</WifiAdminFormAreaLabel>
                        <WifiAdminFormInputBox id="confirm_password"
                                        autofill={"none"}
                                        name="confirm_password"
                                        type="password"
                                        onChange={(data)=>{onFormChanged("confirm_password", data.target.value)}}
                        />
                    </WifiAdminFormAreaFieldSet>

                    <WifiAdminFormAreaFieldSet>
                        <WifiAdminFormAreaLabel>Company Name</WifiAdminFormAreaLabel>
                        <WifiAdminFormInputBox
                            maxLength="128"
                            autofill={"none"}
                            name="organization_name"
                            type="organization_name"
                            onChange={(data)=>{onFormChanged("organization_name", data.target.value)}}
                        />
                    </WifiAdminFormAreaFieldSet>

                    <PortalFormButton
                        onClick={()=>{props.signupButtonPressed(formValues)}}
                    >
                        Sign Up
                    </PortalFormButton>

                    <WifiAdminFormAreaBottom>
                        <WifiAdminFormUL>
                            <WifiAdminFormLI><FormAreaBottomLink href="/login">Login</FormAreaBottomLink></WifiAdminFormLI>
                            <WifiAdminFormLI><FormAreaBottomLink href="https://wifi.marc1.io">Marc1</FormAreaBottomLink></WifiAdminFormLI>
                        </WifiAdminFormUL>
                    </WifiAdminFormAreaBottom>

                </WifiAdminFormBox>
        </WifiAdminBox>
    );
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {signupButtonPressed})(withRouter(SignupContent));
