import React, {useCallback, useRef} from 'react'

export default function useScrollToViewRef() {
  const ref = useRef(null);
  const setRef = useCallback(node => {
        if (ref.current) {
        }

        if (node) {
          // Check if a node is actually passed. Otherwise node would be null.
            node.scrollIntoView({
                behavior: "smooth",
            });
        }
    // Save a reference to the node
    ref.current = node
  }, [])

  return [setRef]
}