import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import {useSelector} from "react-redux";


const CircularDiv = styled.div`
  	position: fixed; /* or absolute */
  	top: 50%;
  	left: 50%;
    width:100%;
    z-index:9999;
    
`;

const CircularContainer = styled.div`
  	position: fixed; /* or absolute */
	top:0;
	left: 0; right: 0;
	bottom:0;
	width: 100%;
	height: 100%;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    
`;

const PercentageContainer = styled.div`
	position: fixed; /* or absolute */
  	top: 50%;
  	left: 50%;
    width:100%;
    z-index:9999;
    width: 33px;
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    height: 29px;
    margin: 50px 50px 50px 45px;
`;

function FullscreenSpinner(props) {
	const [progress, setProgress] = useState(0)
	const upload_progress = useSelector(state => state.campaigns.upload_progress);

	useEffect(()=>{
		if (!_.isEmpty(upload_progress)){
			let total_sum = 0;
			let loaded_sum = 0;
			for (let object_key in upload_progress){
				total_sum += upload_progress[object_key]['total'];
				loaded_sum += upload_progress[object_key]['loaded'];
			}
			setProgress(Math.round((loaded_sum/total_sum * 100) * 100) / 100)
		}
	}, [upload_progress]);

	const renderPercentage = () => {
		if (_.isEmpty(upload_progress))
			return null;
		return (
			<PercentageContainer>
				{progress.toString() + "%"}
			</PercentageContainer>
		);
	};

	const renderLoader = () => {
		return (
			<CircularContainer>
				<CircularDiv>
					<CircularProgress size="8rem"/>
					{renderPercentage()}
				</CircularDiv>
			</CircularContainer>
		);
	};

	return (
	  <React.Fragment>
		  {
			  props.isLoading ? renderLoader() : (<div></div>)
		  }
	  </React.Fragment>

	);
};

FullscreenSpinner.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};

export default FullscreenSpinner;
