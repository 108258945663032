import * as actions  from "../actions/action-types";

const DEFAULT_STATE = [];

export default function(state = DEFAULT_STATE, action){
    switch (action.type) {
        case actions.GET_ALL_STORES_SUCCESS:
            return action.payload;
        case actions.CLEAR_STORE_DATA:
            return DEFAULT_STATE;
        default:
            return state;
    }
}
