import styled from "styled-components";
import {PRIMARY_THEME_COLOR} from "../../consts/themes";

export const LayoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const PaperContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 25px;
    overflow-y: auto;
    
`;

export const paperStyle = {
    topMargin: "10px",
    padding: "1em",
    justifyContent: "center",
    width: "100%",

};

export const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
`;

export const Label = styled.h4`
  color: #808080;
`;

export const gridStyle = {
    paddingTop: "25px"
};

export const gridStyleFlex = {
    display: "flex",
    justifyContent: "center",
    paddingTop: "25px"
};

export const selectStyling = {
    menu: (provided, state) => ({
        ...provided
    }),

    control: (provided, state) => ({
        ...provided,
        borderRadius: "0px",
        borderWidth: "0px 0px 1px 0px",
        borderColor: "#949494",
        marginTop: "10px"
    }),

    singleValue: (provided, state) => {
        return { ...provided };
    },
    multiValue : (provided, state) => {
        let color = "white";
        let backgroundColor = PRIMARY_THEME_COLOR;
        return {...provided, color, backgroundColor}
    },
    multiValueLabel: (provided, state) => {
        let color = "white";
        return {...provided, color}
    },
}
