import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "../../../redux/actions/action-types";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";


function CampaignsTable(props) {
    const dispatch = useDispatch();
    const [pageRows, setPageRows] = useState(10);

    const campaigns = useSelector(state => state.campaigns.campaigns);
    const [columns, setColumns] = React.useState([
                {
                    title: 'Campaign Name',
                    field: 'campaign_name',
                },
                {
                    title: 'Campaign Videos',
                    field: 'campaign_videos',
                    render: rowData => (rowData.campaign_videos ? rowData.campaign_videos.map(displayChips) : "No videos")
                },
                {
                    title: 'Created By',
                    field: 'created_by',
                    render: rowData => (rowData.created_by ? rowData.created_by : "Unknown")
                },
                {
                    title: 'Created At',
                    field: 'created_at',
                    render: (rowData => {
                        let created_at_time = rowData.created_at ? rowData.created_at : null;
                        let time;
                        if (created_at_time)
                            time = new Date(created_at_time);
                        else
                            time = new Date();

                        return time.toLocaleDateString() + " " + time.toLocaleTimeString()

                    })
                }
            ]);

    const displayChips = (data, index) => {
        return (
            <Tooltip title={data} key={index}>
                <Chip
                    label={data}
                    color="default"
                />
            </Tooltip>
        );
    };

    useEffect(() => {
        dispatch({
            type: actions.GET_ALL_CAMPAIGNS
        });
    }, []);

    return (
        <MaterialTable
            title="Campaigns"
            columns={columns}
            style={{width: "100%"}}
            data={campaigns}
            onChangeRowsPerPage={(data) => {
                setPageRows(data)
            }}
            options={{
                pageSize: pageRows,
                pageSizeOptions: [5, 10, 15],
                exportButton: true,
                grouping: true,
                filtering: true
            }}
        />
    );
}

export default connect(null, {})(withRouter(CampaignsTable));
