import styled, {css, keyframes} from "styled-components";
import {PRIMARY_THEME_COLOR} from '../../consts/themes';

export const Paper = styled.div`
    padding: ${props => props.screenSize === 'xsmall' ? '0px' : '40px'};
    margin-top: ${props => props.screenSize === 'xsmall' ? '20px' : '0px'};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PaperBox = styled.div`
    background: #ffffffd9;
    border: 1px solid #dadce0;
    border-radius: 8px;
    display: block;
    width: 450px;
    overflow: hidden;
`;


export const LoginContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
`;

export const Loader = styled.div`
    margin-bottom: -1px;
    max-height: 3px;
    width: 445px;
    border-radius: 5px;
    overflow: hidden;
`;

export const LoaderBar = styled.div`
    width: 40%;
    background-color: ${PRIMARY_THEME_COLOR};
    border-radius: 5px;
    height: 10px;
`;

export const SlideComponent = styled.div`
    overflow: hidden;
`;
