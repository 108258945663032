import {LOCAL_STORAGE_LOADED} from "../actions/action-types";

const DEFAULT_STATE = {};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case LOCAL_STORAGE_LOADED:
            return {...state, ...action.payload};
        default:
            return state;
    }
}