import {CardDiv, ContentContainer, ImageContainer, DisabledOverlay, Image} from "./quick_start_scene_components";
import {h1} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export const QuickStartCardIcon = (props) => {
    const renderDisabledOverlay = () => {
        return (
            <DisabledOverlay id={'thisisdisabled'}/>
        );
    };
    return (
        <CardDiv
            disabled={props.disabled}
            onClick={()=>{props.disabled ? null : props.onClick()}}
        >
            {props.disabled ? renderDisabledOverlay() : null}
            <ImageContainer>
                <Image src={props.icon}  alt="Card Icon" style={{}}/>
            </ImageContainer>
            <ContentContainer>
                <h1>
                    {props.title}
                </h1>
                <div
                    style={{paddingLeft:"5%", paddingRight:"5%"}}
                >
                    <Typography
                        variant={'subtitle1'}
                        color={'secondary'}
                    >
                        {props.description}
                    </Typography>
                </div>
            </ContentContainer>

        </CardDiv>
    )
};

export default QuickStartCardIcon;
