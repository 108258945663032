class Log {

    static info(message) {
        Log.logConsole(message, 'INFO');
    }

    static warn(message) {
        Log.logConsole(message, 'WARN');
    }

    static error(message) {
        Log.logConsole(message, 'ERROR');
    }

    static logConsole(message, logLevel) {
        let timestamp = `[${new Date().toISOString()}] [${logLevel}]`;
        console.log(timestamp, message);
    }
}

export default Log;